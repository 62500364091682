import { Box, Grid, Typography } from "@mui/material";
import LogOut from "../../assets/svgs/LogOut";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import SignOutPopUp from "../SignOutComponents/SignOutPopUp";
import { useAppSelector } from "../../utils/redux-hooks";
import SpeakerSvg from "../../assets/svgs/SpeakerSvg";
import ThunderSvg from "../../assets/svgs/ThunderSvg";
import {
  checkAutoReplyPermission,
  checkInboxSettingsPermission,
  checkWorkflowsPermission,
} from "../../utils/permissions";
import { RiChatSettingsFill } from "react-icons/ri";
import { TbShareplay } from "react-icons/tb";
import { LuWorkflow } from "react-icons/lu";
import { RiRobot2Fill } from "react-icons/ri";
import { PiOptionBold } from "react-icons/pi";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const AutomationSideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;
  const [activeSection, setActiveSection] = useState("");
  // const getManageAccount = useAppSelector((state) => state.manageAccountData);
  // const data = getManageAccount?.data;
  const hasInboxSettingsPermission = checkInboxSettingsPermission(
    getUserPermissionData?.automation
  );
  const hasAutoReplyPermission = checkAutoReplyPermission(
    getUserPermissionData?.automation
  );
  const hasWorkflowsPermission = checkWorkflowsPermission(
    getUserPermissionData?.automation
  );
  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/automation/${path}`);
    setActiveSection(path);
  };

  // const [openDialog, setOpenDialog] = useState(false);

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    setActiveSection(path || "");
  }, [location.pathname]);
  // console.log("activeSection", activeSection);
  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <ThunderSvg />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Automation
        </Typography>
      </Box>

      <Box>
        {hasInboxSettingsPermission && (
          <Box
            m={2}
            textAlign="start"
            pl={1}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("inbox") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("inbox-settings")}
          >
            <Typography fontSize="14px" sx={{display: "flex", alignItems: "center"}}><RiChatSettingsFill />&nbsp; Inbox Settings</Typography>
          </Box>
        )}
        {hasAutoReplyPermission && (
          <Box
            m={2}
            textAlign="start"
            pl={1}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("auto-reply") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("auto-reply")}
          >
            <Typography fontSize="14px" sx={{display: "flex", alignItems: "center"}}><TbShareplay  />&nbsp;Auto Reply</Typography>
          </Box>
        )}
        {hasWorkflowsPermission && (
          <Box
            m={2}
            textAlign="start"
            pl={1}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("workflows") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("workflows")}
          >
            <Typography fontSize="14px" sx={{display: "flex", alignItems: "center"}}><LuWorkflow  />&nbsp;Workflows</Typography>
          </Box>
        )}
        {/* {hasWorkflowsPermission && ( */}
        <Box
          m={2}
          textAlign="start"
          pl={1}
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("optin-management") ? classes.activeItem : ""
          }`}
          onClick={() => handleNavigation("optin-management")}
        >
          <Typography fontSize="14px" sx={{display: "flex", alignItems: "center"}}><PiOptionBold  />&nbsp;Optin Management</Typography>
        </Box>
        {/* )} */}
        {/* {hasWorkflowsPermission && ( */}
        <Box
          m={2}
          textAlign="start"
          pl={1}
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("bot") ? classes.activeItem : ""
          }`}
          onClick={() => handleNavigation("bot")}
        >
          <Typography fontSize="14px" sx={{display: "flex", alignItems: "center"}}><RiRobot2Fill  />&nbsp;BOT</Typography>
        </Box>
        {/* )} */}
      </Box>
    </Grid>
  );
};

export default AutomationSideBar;
