import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  // RichUtils,
  // SelectionState,
  // convertFromHTML,
  // convertFromRaw,
  convertToRaw,
} from "draft-js";
// import { Editor } from 'react-draft-wysiwyg';
import { makeStyles } from "@mui/styles";
import "draft-js/dist/Draft.css";
import React, { useEffect, useRef, useState } from "react";
import { bgColors } from "../../utils/bgColors";
// import ChatEmojiIcon from "../../assets/svgs/ChatEmojiIcon";
// import TextFeildWithBorderComponet from "../../components/common/TextFieldWithBorderComponent";
// import ChatFileIcon from "../../assets/svgs/ChatFileIcon";
// import ChatTemplate from "../../assets/svgs/ChatTemplate";
// import VectorSvg from "../../assets/svgs/VectorSvg";
import { WorkingHoursPoup } from "../../components/AutomationComponents/WorkingHoursPoup";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { getWorkingHours } from "../../redux/slices/Automation/GetWorkingHoursSlice";
import { reactDraftWysiwygToolbarOptionsarticle } from "../../utils/react-draft-wysiwyg-options";
import { updateInboxSettings } from "../../redux/slices/Automation/UpdateInboxSettingsSlice";
import { getInboxSettings } from "../../redux/slices/Automation/GetInboxSettingsSlice";
import { toastActions } from "../../utils/toastSlice";
import { EmailOutlined } from "@mui/icons-material";
import { CAMPAIGN_API } from "../../Apis/Campaign/Campaign";
import { DraftEditorComponent } from "../../components/common/DraftEditorComponent";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import { checkInboxSettingsPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";
import { parseTextToDraft, parseTextToHtml } from "../../utils/functions";
import LoadingComponent from "../../components/common/LoadingComponent";
import { getNextVariableCount } from "../../components/AutomationComponents/WorkflowComponents/functions";
import { RiChatSettingsFill } from "react-icons/ri";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  chatTextBox: {
    position: "relative",
    border: "1px solid #262738",
    borderRadius: 8,
  },
  toolBarStyle: {
    border: "none",
    backgroundColor: "rgb(247, 244, 244)",
    padding: "10px 0",
    marginBottom: 0,
    order: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  wrapperClassName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  editorClassName: {
    padding: "10px",
    minHeight: "60px",
    maxHeight: "200px",
    fontSize: "14px",
    order: 1,
  },
  fileUploadInput: {
    display: "none",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textColor: {
    color: bgColors.black1,
    fontSize: "16px",
    opacity: "80%",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  resetBtnContainer: {
    backgroundColor: bgColors.white,
    // border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "100px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  variable: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  changePasswordText: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  welcomeContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  welcometext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  delayedContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  delayedtext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  hours: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "15px !important",
  },
  time: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "16px !important",
    // fontWeight: "Bold !important",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "100%",
    margin: "0 auto",
    opacity: "20%",
    marginTop: "5px",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
});

// const options = Array.from({ length: 24 }, (v, k) => k);

// const minuteOptions = Array.from({ length: 13 }, (v, k) => k * 5);

// const staticVariables = [{
//   label: "Name",
//   value: 'Name'
// },
// {
//   label: "Contact",
//   value: 'Contact1'
// },
// {
//   label: "Country Code",
//   value: 'CountryCode'
// },
// {
//   label: "Country Name",
//   value: 'CountryName'
// },
// {
//   label: "Email",
//   value: 'Email'
// },
// ]
const dictionary = {
  Name: "Name",
  CountryCode: "CountryCode",
  CountryName: "CountryName",
  Contact: "Contact1",
  Email: "Email",
};
const dictionaryOptions = Object.entries(dictionary).map(([key, value]) => ({
  key,
  value,
}));
const Automation = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const chatAreaRef = useRef<HTMLDivElement>(null);

  const getLoginData = useAppSelector((state: any) => state.adminLogin.data);
  const getInboxData = useAppSelector(
    (state: any) => state.getInboxSettings.data
  );
  const getWorkingHoursData = useAppSelector(
    (state: any) => state?.getWorkingHours?.data
  );
  const getWorkingHoursLoading = useAppSelector(
    (state: any) => state?.getWorkingHours?.status
  );
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state?.getUserPermissions
  );
  const getuserPermissionData = getuserPermissionSlice?.data;
  const automationPermissionsArray = getuserPermissionData?.automation;
  const hasInboxSettingsPermission = checkInboxSettingsPermission(
    automationPermissionsArray
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [workingHoursData, setWorkingHoursData] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [outOfOfficeMessageTooltip, setOutOfOfficeMessageTooltip] =
    useState(false);
  // console.log(outOfOfficeMessageTooltip);
  const [welcomeMessageTooltip, setWelcomeMessageTooltip] = useState(false);
  // console.log(welcomeMessageTooltip);
  const [delayedMessageTooltip, setDelayedMessageTooltip] = useState(false);
  // console.log(delayedMessageTooltip);

  const inboxSettingsPermissionsObject = automationPermissionsArray?.find(
    (item: any) => Object.prototype.hasOwnProperty.call(item, "inboxSettings")
  );

  const inboxSettingsPermissionsActions = inboxSettingsPermissionsObject
    ? inboxSettingsPermissionsObject.inboxSettings
    : [];

  const hasAccess = (permission: any) => {
    if (inboxSettingsPermissionsActions?.includes(permission)) {
      return true;
    }
    return false;
  };
  // console.log(hasAccess);

  const [uploadingSections, setUploadingSections] = useState<
    Record<number, boolean>
  >({});

  const formatContent = (contentState: ContentState): string => {
    const blocks = convertToRaw(contentState).blocks;
    // console.log(blocks)
    let formattedText = "";
    let listItemCounter = 1;

    const applyStyles = (textSegment: string, styles: string[]): string => {
      let styledText = textSegment;
      styles.forEach((style) => {
        if (style === "BOLD") styledText = `*${styledText.trim()}*`;
        if (style === "ITALIC") styledText = `_${styledText.trim()}_`;
        if (style === "STRIKETHROUGH") styledText = `~${styledText.trim()}~`;
      });
      return styledText;
    };

    blocks.forEach((block, index) => {
      if (index !== 0) {
        formattedText += "\n";
      }
      let blockText = "";
      const text = block.text;
      const styleRanges = block.inlineStyleRanges.sort(
        (a, b) => a.offset - b.offset
      );

      let pos = 0;

      const getActiveStyles = (pos: number) => {
        return styleRanges
          .filter(
            (range) => range.offset <= pos && pos < range.offset + range.length
          )
          .map((range) => range.style);
      };

      while (pos < text.length) {
        let nextChange = text.length;
        if (styleRanges.length > 0) {
          nextChange = Math.min(
            nextChange,
            ...styleRanges
              .map((range) => range.offset)
              .filter((offset) => offset > pos),
            ...styleRanges
              .map((range) => range.offset + range.length)
              .filter((offset) => offset > pos)
          );
        }

        const segmentText = text.slice(pos, nextChange);
        const stylesAtPos = getActiveStyles(pos);

        if (segmentText.length > 0) {
          blockText += applyStyles(segmentText, stylesAtPos);
        }

        pos = nextChange;
      }

      if (block.type === "unordered-list-item") {
        blockText = "- " + blockText.trim();
      } else if (block.type === "ordered-list-item") {
        blockText = listItemCounter + ". " + blockText.trim();
        listItemCounter++;
      } else {
        listItemCounter = 1;
      }

      formattedText += blockText;
    });
    // console.log(formattedText)
    return formattedText;
  };

  // Function to parse text to Draft.js content
  // const parseTextToDraft = (text: string): ContentState => {
  //   // Handle unordered lists
  //   let inUl = false;
  //   let htmlText = text.replace(/^-\s+(.*)$/gm, (match, p1) => {
  //     if (!inUl) {
  //       inUl = true;
  //       return `<ul><li>${p1}</li>`;
  //     }
  //     return `<li>${p1}</li>`;
  //   });
  //   if (inUl) htmlText += "</ul>";

  //   // Handle ordered lists
  //   let inOl = false;
  //   htmlText = htmlText.replace(/^\d+\.\s+(.*)$/gm, (match, p1) => {
  //     if (!inOl) {
  //       inOl = true;
  //       return `<ol><li>${p1}</li>`;
  //     }
  //     return `<li>${p1}</li>`;
  //   });
  //   if (inOl) htmlText += "</ol>";

  //   // Handle other formatting
  //   htmlText = htmlText
  //     .replace(/\*(.*?)\*/g, "<b>$1</b>")
  //     .replace(/_(.*?)_/g, "<i>$1</i>")
  //     .replace(/~(.*?)~/g, "<strike>$1</strike>")
  //     .replace(/(?<!<\/li>)\n(?!<[uo]l>|<li>)/g, "<br>");

  //   const blocksFromHTML = convertFromHTML(htmlText);
  //   return ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  // };

  // Function to insert text at cursor position in the editor
  const insertTextAtCursor = (
    editorState: EditorState,
    text: string
  ): EditorState => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      text
    );
    return EditorState.push(editorState, newContentState, "insert-characters");
  };

  // Function to convert formatted text to HTML
  // const convertFormattedTextToHtml = (text: string): string => {
  //   let html = text
  //     ?.replace(/\*(.*?)\*/g, "<strong>$1</strong>")
  //     ?.replace(/_(.*?)_/g, "<em>$1</em>")
  //     ?.replace(/~(.*?)~/g, "<del>$1</del>");

  //   // Handle unordered lists
  //   let inUl = false;
  //   html = html?.replace(/^- (.*)$/gm, (match, p1) => {
  //     if (!inUl) {
  //       inUl = true;
  //       return `<ul><li>${p1}</li>`;
  //     }
  //     return `<li>${p1}</li>`;
  //   });
  //   if (inUl) html += "</ul>";

  //   // Handle ordered lists
  //   let inOl = false;
  //   let listCounter = 1;
  //   html = html?.replace(/^(\d+)\. (.*)$/gm, (match, p1, p2) => {
  //     if (!inOl) {
  //       inOl = true;
  //       listCounter = parseInt(p1);
  //       return `<ol start="${listCounter}"><li>${p2}</li>`;
  //     }
  //     listCounter++;
  //     return `<li>${p2}</li>`;
  //   });
  //   if (inOl) html += "</ol>";

  //   // Replace newlines with <br> tags, but not within list items
  //   html = html?.replace(/(?<!<\/li>)\n(?!<[uo]l>|<li>)/g, "<br>");

  //   return html;
  // };

  const getFileRes = async (file: File): Promise<string | null> => {
    if (file) {
      try {
        const data = {
          mediaFile: file,
        };
        const getFileRes = await CAMPAIGN_API.uploadCampaignMedia(data);
        if (getFileRes?.data) {
          return getFileRes.data;
        }
      } catch (error: any) {
        console.error("Error uploading file:", error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: error?.response?.data?.message,
          })
        );
      }
    }
    return null;
  };
  const isImageUrl = (url: any) => {
    return url?.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  // Function to handle file upload
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    sectionIndex: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check if the file is an image or video
      const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.name);
      const isVideo = /\.(mp4|avi|mov)$/i.test(file.name);

      if (!isImage && !isVideo) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Please upload only image or video files.",
          })
        );
        return;
      }

      if (file.size > 50 * 1024 * 1024) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "File size should not exceed 50MB.",
          })
        );
        return;
      }

      setFile(file);
      setUploadingSections((prev) => ({ ...prev, [sectionIndex]: true }));

      try {
        const awsUrl = await getFileRes(file);

        setSections((prevSections: any) =>
          prevSections.map((section: any, i: number) =>
            i === sectionIndex ? { ...section, mediaUrl: awsUrl } : section
          )
        );
      } catch (error) {
        console.error("Error uploading file:", error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Failed to upload file. Please try again.",
          })
        );
      } finally {
        setUploadingSections((prev) => ({ ...prev, [sectionIndex]: false }));
      }
    }
  };

  // Custom block renderer function
  const blockRendererFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: Media,
        editable: false,
      };
    }
    return null;
  };

  // Media component for rendering images and videos
  const Media = (props: any) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src, type } = entity.getData();
    let media;
    if (type === "IMAGE") {
      media = (
        <img
          src={src}
          alt="uploaded"
          style={{ height: "100px", width: "100px" }}
        />
      );
    } else if (type === "VIDEO") {
      media = (
        <video src={src} controls style={{ height: "100px", width: "300px" }} />
      );
    }
    return media;
  };

  const [sections, setSections] = useState<any>([
    {
      name: "Out of Office Message",
      feature: 0,
      editorState: EditorState.createEmpty(),
      isExpanded: false,
      isEditing: false,
      enabled: false,
      variables: [],
      description:
        "Set up your working hours and Out Of Office Message. Please note that the Auto Reply gets triggered for new users and users whose conversation is marked resolved.",
      mediaUrl: null,
      tab: "message",
    },
    {
      name: "Welcome Message",
      feature: 1,
      editorState: EditorState.createEmpty(),
      isExpanded: false,
      isEditing: false,
      enabled: false,
      variables: [],
      description:
        "Configure Greeting message to be triggered when new customers reach out to your business for the first time or existing customers reach out after a period of 24 hours.",
      mediaUrl: null,
      tab: "message",
    },
    {
      name: "Delayed Message",
      feature: 2,
      editorState: EditorState.createEmpty(),
      isExpanded: false,
      isEditing: false,
      enabled: false,
      variables: [],
      description:
        "Configure Auto Replies when you are delayed in responding to customer messages. Setup your delay time and the message to be triggered.",
      mediaUrl: null,
      delayHours: 1,
      delayMinutes: 5,
      tab: "message",
    },
  ]);
  // console.log(sections[3]);

  const [currentFeature] = useState(0);

  // Fetch initial data for the current
  useEffect(() => {
    if (getLoginData?.companyId) {
      dispatch(
        getInboxSettings({
          businessId: getLoginData.companyId,
          userId: getLoginData.userId,
          feature: currentFeature,
        })
      );
    }
  }, [dispatch, getLoginData, currentFeature]);

  // Update section when API data is received
  useEffect(() => {
    if (getInboxData && getInboxData.pre_Data) {
      const { pre_Data, variables } = getInboxData;
      setSections((prevSections: any) =>
        prevSections.map((section: any) => {
          if (section.feature === pre_Data.feature) {
            return {
              ...section,
              editorState: EditorState.createWithContent(
                parseTextToDraft(pre_Data.body)
              ),
              enabled: pre_Data.enabled,
              variables: variables || [], // Ensure this is correctly set
              mediaUrl: pre_Data.mediaAwsUrl || null,
              ...(pre_Data.feature === 2 && pre_Data.delay
                ? parseDelay(pre_Data.delay)
                : {}),
            };
          }
          return section;
        })
      );
    }
  }, [getInboxData]);

  // Helper function to parse delay string
  const parseDelay = (delay: string) => {
    const [hours, minutes] = delay.split(":").map(Number);
    return { delayHours: hours, delayMinutes: minutes };
  };

  // const handleSectionToggle = (index: number) => {
  //   setSections((prevSections: any) =>
  //     prevSections.map((section: any, i: any) =>
  //       i === index ? { ...section, isExpanded: !section.isExpanded } : section
  //     )
  //   );
  // };
  const handleSectionToggle = (index: number) => {
    const permissionMap: Record<number, string> = {
      0: "outOfOfficeMessage",
      1: "welcomeMessage",
      2: "delayedMessage",
    };

    const tooltipMap: Record<
      number,
      React.Dispatch<React.SetStateAction<boolean>>
    > = {
      0: setOutOfOfficeMessageTooltip,
      1: setWelcomeMessageTooltip,
      2: setDelayedMessageTooltip,
    };

    const permissionKey = permissionMap[index];
    const tooltipSetter = tooltipMap[index];
    // console.log(permissionKey);
    // console.log(tooltipSetter);

    if (!permissionKey || !tooltipSetter) return;

    const hasPermission = hasAccess(permissionKey);
    // console.log("called");
    if (hasPermission) {
      // console.log(hasPermission);
      setSections((prevSections: any) =>
        prevSections.map((section: any, i: any) =>
          i === index
            ? { ...section, isExpanded: !section.isExpanded }
            : section
        )
      );
    } else {
      tooltipSetter(true);
      setTimeout(() => {
        tooltipSetter(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (getLoginData?.companyId) {
      dispatch(
        getWorkingHours({
          businessId: getLoginData?.companyId,
          userId: getLoginData?.userId,
        })
      ).then((res) => setWorkingHoursData(res.payload));
    }
  }, [dispatch, getLoginData]);

  useEffect(() => {
    sections.forEach((section: any) => {
      dispatch(
        getInboxSettings({
          businessId: getLoginData?.companyId,
          userId: getLoginData?.userId,
          feature: section?.feature,
        })
      );
    });
  }, []);

  const handleEditSection = (index: number) => {
    setSections((prevSections: any) => {
      const updatedSections = prevSections.map((section: any, i: any) =>
        i === index
          ? {
              ...section,
              isEditing: true,
              variables: section.variables || [],
            }
          : section
      );

      return updatedSections;
    });
  };

  const handleEditorStateChange = (
    index: number,
    newEditorState: EditorState
  ) => {
    const content = newEditorState?.getCurrentContent();
    const plainText = content.getPlainText();
    const cleanText = plainText.replace(/\s+/g, " ").trim();
    const newContent = ContentState.createFromText(cleanText);
    const cleanedEditorState = EditorState.push(
      newEditorState,
      newContent,
      "insert-characters"
    );
    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === index
          ? {
              ...section,
              editorState: newEditorState,
              variables: extractVariables(
                cleanedEditorState,
                section.variables
              ),
            }
          : section
      )
    );
  };

  const handleSwitchChange = async (index: number, checked: boolean) => {
    // console.log(index, checked);
    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === index ? { ...section, enabled: checked } : section
      )
    );
    await new Promise((resolve) => setTimeout(resolve, 0));
    handleSaveSection(index, checked);
  };

  const handleSaveSection = async (index: number, enabledState: boolean) => {
    const section = sections[index];
    const formattedContent = formatContent(
      section?.editorState?.getCurrentContent()
    );
    if (!formattedContent.trim()) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Message content cannot be empty.",
        })
      );
      return;
    }
    if (
      section.feature === 2 &&
      section.delayHours === 0 &&
      section.delayMinutes === 0
    ) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Please set a delay time for the Delayed Message.",
        })
      );
      return;
    }

    const allVariablesFilled = section.variables.every(
      (v: any) => v.variable && v.value && v.fallbackvalue
    );

    if (!allVariablesFilled) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Please fill all variable fields before saving.",
        })
      );
      return;
    }

    const data = {
      businessId: getLoginData?.companyId,
      userId: getLoginData?.userId,
      inboxSettings: {
        feature: section?.feature,
        message: formattedContent,
        enabled: enabledState,
        mediaUrl: section?.mediaUrl,
        variables: section?.variables,
        delay:
          section.feature === 2
            ? `${section?.delayHours}:${section?.delayMinutes}:00`
            : null,
      },
    };

    const combinedDays: any = {};
    // console.log("workingHoursByDay", workingHoursByDay, daysOfWeek);

    Object.entries(workingHoursByDay)?.forEach(([day, hours]: any[]) => {
      if (!Array.isArray(hours)) {
        console.error(`Invalid hours for day ${day}:`, hours);
        return; // Skip this iteration if hours is not an array
      }

      const nextDay = daysOfWeek[(daysOfWeek?.indexOf(day) + 1) % 7];
      const nextHours: any = workingHoursByDay[nextDay];

      if (
        nextHours &&
        Array.isArray(nextHours) &&
        hours.join(", ") === nextHours.join(", ")
      ) {
        if (!combinedDays[day]) {
          combinedDays[day] = [nextDay];
        } else {
          combinedDays[day].push(nextDay);
        }
        delete workingHoursByDay[nextDay];
      }
    });

    try {
      const res = await dispatch(updateInboxSettings(data));
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: section.name
              ? `${section.name} settings updated successfully`
              : "Inbox settings updated successfully",
          })
        );

        dispatch(
          getInboxSettings({
            businessId: getLoginData?.companyId,
            userId: getLoginData?.userId,
            feature: section.feature,
          })
        );
        setSections((prevSections: any) =>
          prevSections.map((s: any, i: any) =>
            i === index ? { ...s, isEditing: false } : s
          )
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "Failed to update inbox settings. Please try again.",
          })
        );
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleVariableChange = (
    sectionIndex: number,
    variableIndex: number,
    field: string,
    value: string
  ) => {
    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === sectionIndex
          ? {
              ...section,
              variables: section.variables.map((v: any, j: number) =>
                j === variableIndex ? { ...v, [field]: value } : v
              ),
            }
          : section
      )
    );
  };

  const handleRemoveImage = (index: number) => {
    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === index ? { ...section, mediaUrl: null } : section
      )
    );
  };

  const handleAddVariable = (index: number) => {
    const nextCount = getNextVariableCount(
      sections[index].editorState,
      "editor"
    );
    const variableText = `{{${nextCount}}}`;
    const newEditorState = insertTextAtCursor(
      sections[index].editorState,
      variableText
    );

    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === index
          ? {
              ...section,
              editorState: newEditorState,
              variables: extractVariables(newEditorState),
            }
          : section
      )
    );
  };

  const handleDelayChange = (
    index: number,
    field: "delayHours" | "delayMinutes",
    value: number
  ) => {
    setSections((prevSections: any) =>
      prevSections.map((section: any, i: any) =>
        i === index ? { ...section, [field]: value } : section
      )
    );
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // function convertTimeToAMPM(time24: string): string {
  //   const [hours, minutes] = time24.split(":").map(Number);
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   const hourIn12HourFormat = hours % 12 || 12;
  //   return `${hourIn12HourFormat}:${minutes
  //     .toString()
  //     .padStart(2, "0")} ${ampm}`;
  // }

  // const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // const workingHoursByDay: any = {};
  // console.log(workingHoursByDay)

  // // Ensure getWorkingHoursData is an array before using forEach
  // if (Array.isArray(getWorkingHoursData?.daysOfWeek)) {
  //   getWorkingHoursData?.daysOfWeek?.forEach((item: any) => {
  //     const dayOfWeek = daysOfWeek[item.dayOfWeek];
  //     if (!workingHoursByDay[dayOfWeek]) {
  //       workingHoursByDay[dayOfWeek] = [];
  //     }
  //     workingHoursByDay[dayOfWeek].push(
  //       `${convertTimeToAMPM(item.from)} to ${convertTimeToAMPM(item.to)}`
  //     );
  //   });
  // } else {
  //   console.error("getWorkingHoursData is not an array or is undefined");
  // }

  // const combinedDays: any = {};

  // Object.entries(workingHoursByDay).forEach(([day, hours]: any[]) => {
  //   const nextDay = daysOfWeek[(daysOfWeek.indexOf(day) + 1) % 7];
  //   const nextHours = workingHoursByDay[nextDay];
  //   if (nextHours && hours.join(", ") === nextHours.join(", ")) {
  //     if (!combinedDays[day]) {
  //       combinedDays[day] = [nextDay];
  //     } else {
  //       combinedDays[day].push(nextDay);
  //     }
  //     delete workingHoursByDay[nextDay];
  //   }
  // });

  function convertTimeToAMPM(time24: string): string {
    const [hours, minutes] = time24.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const hourIn12HourFormat = hours % 12 || 12;
    return `${hourIn12HourFormat}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  }

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const workingHoursByDay: Record<string, string> = {};

  // Populate workingHoursByDay
  daysOfWeek.forEach((day) => {
    workingHoursByDay[day] = "";
  });

  if (Array.isArray(getWorkingHoursData?.daysOfWeek)) {
    getWorkingHoursData?.daysOfWeek?.forEach((item: any) => {
      const dayOfWeek = daysOfWeek[item?.dayOfWeek];
      workingHoursByDay[dayOfWeek] = `${convertTimeToAMPM(
        item?.from
      )} to ${convertTimeToAMPM(item?.to)}`;
    });
  } else {
    console.error("getWorkingHoursData is not an array or is undefined");
  }

  const groupedSchedule: [string, string, string][] = [];
  let startDay = "";
  let endDay = "";
  let currentHours = "";

  daysOfWeek?.forEach((day, index) => {
    if (workingHoursByDay[day] !== currentHours) {
      if (startDay) {
        groupedSchedule?.push([startDay, endDay, currentHours]);
      }
      startDay = day;
      currentHours = workingHoursByDay[day];
    }
    endDay = day;

    if (index === daysOfWeek.length - 1 && startDay) {
      groupedSchedule.push([startDay, endDay, currentHours]);
    }
  });

  const formatDayRange = (start: string, end: string) => {
    return start === end ? start : `${start}-${end}`;
  };

  const extractVariables = (
    editorState: EditorState,
    existingVariables: any[] = []
  ) => {
    const content = editorState?.getCurrentContent()?.getPlainText();
    const variableRegex = /\{\{(\d+)\}\}/g;
    const matches = content.match(variableRegex);

    if (!matches)
      return existingVariables.filter((v) => content.includes(v.variable));

    return matches.map((match) => {
      const variable = match.replace(/\{\{|\}\}/g, "");
      const existingVar = existingVariables.find(
        (v) => v.variable === `{{${variable}}}`
      );
      return (
        existingVar || {
          variable: `{{${variable}}}`,
          value: "",
          fallbackValue: "",
        }
      );
    });
  };

  // console.log("section", sections);

  return (
    <>
      {hasInboxSettingsPermission ? (
        <Grid container className={classes.mainContainer} ref={chatAreaRef}>
          <Grid item className={classes.bgContainer}>
            <Box className={classes.manageContainer}>
              <Box ml={4} mt={2} mb={1}>
                <Typography variant="h6" className={classes.blackColor} sx={{display: "flex", alignItems: "center"}}>
                <RiChatSettingsFill />&nbsp;Inbox Settings
                </Typography>
              </Box>
            </Box>
            <Box ml={4}>
              <Typography className={classes.textColor} variant="body2">
                Manage Auto replies to user messages and improve your customer
                experience.
              </Typography>
            </Box>
            <Box m={3}>
              <Box p={3} className={classes.mainBorderStyles}>
                <Box display="flex" alignItems="center" mb={2}>
                  <AccessTimeIcon />
                  <Typography
                    variant="h6"
                    ml={1}
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Setup your working hours
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => setDialogOpen(true)}
                  >
                    <EditIconSvg />
                  </IconButton>
                </Box>
                {getWorkingHoursLoading === "loading" ? (
                  <LoadingComponent height="auto" color={bgColors?.blue} />
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    mb={2}
                    sx={{ fontSize: "13px" }}
                  >
                    {groupedSchedule
                      ?.map(
                        ([start, end, hours], index) =>
                          hours && (
                            <span key={index}>
                              {formatDayRange(start, end)}: {hours}
                              {index < groupedSchedule.length - 1 ? ", " : ""}
                            </span>
                          )
                      )
                      .filter(Boolean)}
                  </Typography>
                )}
              </Box>
            </Box>

            {sections?.map((section: any, index: any) => (
              <Box key={index} m={3}>
                <Box
                  p={3}
                  className={classes.mainBorderStyles}
                  sx={{
                    "&:hover": {
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                  >
                    <Tooltip
                      title="Access Denied"
                      placement="left"
                      open={
                        index === 0
                          ? outOfOfficeMessageTooltip
                          : index === 1
                          ? welcomeMessageTooltip
                          : delayedMessageTooltip
                      }
                      onClose={() =>
                        index === 0
                          ? setOutOfOfficeMessageTooltip(false)
                          : index === 1
                          ? setWelcomeMessageTooltip(false)
                          : setDelayedMessageTooltip(false)
                      }
                    >
                      <Grid item xs>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: `underline`,
                            },
                          }}
                          onClick={() => handleSectionToggle(index)}
                        >
                          <EmailOutlined />

                          <Typography
                            variant="subtitle1"
                            ml={1}
                            sx={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {section.name}
                          </Typography>
                          {section?.isExpanded ? (
                            <IconButton
                              size="small"
                              sx={{ ml: 1 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditSection(index);
                              }}
                            >
                              <EditIconSvg />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Typography
                          mt={2}
                          sx={{ fontSize: "14px", color: "gray" }}
                        >
                          {section.description}
                        </Typography>
                      </Grid>
                    </Tooltip>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          mr={1}
                          color={
                            section.enabled ? "success.main" : "text.secondary"
                          }
                        >
                          {section.enabled ? "Enabled" : "Disabled"}
                        </Typography>
                        <Switch
                          checked={section.enabled}
                          disabled={
                            index === 0
                              ? !hasAccess("outOfOfficeMessage")
                              : index === 1
                              ? !hasAccess("welcomeMessage")
                              : index === 2
                              ? !hasAccess("delayedMessage")
                              : index === 3
                              ? !hasAccess("optedIn")
                              : !hasAccess("optedOut")
                          }
                          // onChange={(e) =>
                          //   handleSwitchChange(index, e.target.checked)
                          // }
                          onChange={(e) => {
                            if (index === 3 || index === 4) {
                              return; // Prevent onChange from being called for index 3 or 4
                            }
                            handleSwitchChange(index, e.target.checked);
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {section.isExpanded && (
                    <>
                      {section.isEditing ? (
                        <>
                          <Box position="relative" display="inline-block">
                            {uploadingSections[index] ? (
                              <Box
                                width="320px"
                                height="200px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bgcolor="grey.200"
                                borderRadius={1}
                              >
                                <CircularProgress size={24} />
                              </Box>
                            ) : section.mediaUrl ? (
                              isImageUrl(section.mediaUrl) ? (
                                <img
                                  src={section.mediaUrl}
                                  alt="Section media"
                                  style={{ height: "150px", width: "250px" }}
                                />
                              ) : (
                                <video
                                  src={section.mediaUrl}
                                  style={{ height: "170px", width: "300px" }}
                                  controls
                                />
                              )
                            ) : null}
                            {section.mediaUrl && !uploadingSections[index] && (
                              <IconButton
                                size="small"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  // transform: "translate(50%, -50%)",
                                  backgroundColor: "white",
                                  boxShadow: 1,
                                }}
                                onClick={() => handleRemoveImage(index)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                          <DraftEditorComponent
                            editorState={section.editorState}
                            handleEditorStateChange={(newState: any) =>
                              handleEditorStateChange(index, newState)
                            }
                            handleSaveInboxSettings={() =>
                              handleSaveSection(index, section?.enabled)
                            }
                            reactDraftWysiwygToolbarOptionsarticle={
                              reactDraftWysiwygToolbarOptionsarticle
                            }
                            blockRendererFn={blockRendererFn}
                            file={file}
                            handleFileUpload={(event: any) =>
                              handleFileUpload(event, index)
                            }
                            isSendButton={true}
                            mediaUrl={section.mediaUrl}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mt: 2,
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={classes.variable}
                              onClick={() => handleAddVariable(index)}
                              style={{
                                cursor: "pointer",
                                color: "primary.main",
                              }}
                            >
                              + Add Variables
                            </Typography>
                          </Box>
                          {section.variables?.length > 0 && (
                            <Box mt={2}>
                              {section.variables?.map(
                                (variable: any, varIndex: number) => (
                                  <Box key={varIndex} display="flex" mb={1}>
                                    <TextField
                                      value={variable.variable || ""}
                                      disabled
                                      size="small"
                                      sx={{
                                        mr: 1,
                                        width: "20%",
                                        height: "40px",
                                        borderRadius: "10px",
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "10px",
                                        },
                                      }}
                                    />
                                    <FormControl
                                      sx={{ mr: 1, width: "20%" }}
                                      size="small"
                                    >
                                      <Select
                                        value={variable.value || ""}
                                        onChange={(e) =>
                                          handleVariableChange(
                                            index,
                                            varIndex,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                        sx={{
                                          // width: "50px",
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        displayEmpty
                                        renderValue={(selected) => {
                                          // Find the selected key based on the value
                                          const selectedOption =
                                            dictionaryOptions.find(
                                              (option) =>
                                                option.value === selected
                                            );
                                          if (!selectedOption) {
                                            return (
                                              <em style={{ color: "gray" }}>
                                                Variable
                                              </em>
                                            );
                                          }
                                          return selectedOption.key;
                                        }}
                                      >
                                        {dictionaryOptions?.map(
                                          ({ key, value }) => (
                                            <MenuItem key={value} value={value}>
                                              {key}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <TextField
                                      placeholder="Fallback value"
                                      value={variable.fallbackvalue || ""}
                                      onChange={(e: any) =>
                                        handleVariableChange(
                                          index,
                                          varIndex,
                                          "fallbackvalue",
                                          e.target.value
                                        )
                                      }
                                      size="small"
                                      sx={{
                                        width: "20%",
                                        height: "40px",
                                        borderRadius: "10px",
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "10px",
                                        },
                                      }}
                                    />
                                  </Box>
                                )
                              )}
                            </Box>
                          )}

                          {section.feature === 2 && (
                            <Box mt={2}>
                              <Typography variant="body2">Delay</Typography>
                              <Select
                                value={section.delayHours}
                                onChange={(e) =>
                                  handleDelayChange(
                                    index,
                                    "delayHours",
                                    Number(e.target.value)
                                  )
                                }
                                sx={{
                                  // width: "50px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      height: "60vh",
                                      width: "100px",
                                      overflowY: "scroll",
                                      position: "relative",
                                      scrollBehavior: "smooth",
                                      // Scrollbar styling
                                      "&::-webkit-scrollbar": {
                                        cursor: "pointer",
                                        width: "6px",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        cursor: "pointer",
                                        backgroundColor: "#cdcdcd",
                                        borderRadius: "8px",
                                        transition:
                                          "background-color 0.2s ease-in-out",
                                      },
                                    },
                                  },
                                }}
                              >
                                {Array.from({ length: 24 }, (_, i) => (
                                  <MenuItem key={i} value={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Select
                                value={section.delayMinutes}
                                onChange={(e) =>
                                  handleDelayChange(
                                    index,
                                    "delayMinutes",
                                    Number(e.target.value)
                                  )
                                }
                                sx={{
                                  marginLeft: "10px",
                                  // width: "50px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      height: "60vh",
                                      width: "100px",
                                      overflowY: "scroll",
                                      position: "relative",
                                      scrollBehavior: "smooth",
                                      // Scrollbar styling
                                      "&::-webkit-scrollbar": {
                                        cursor: "pointer",
                                        width: "6px",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        cursor: "pointer",
                                        backgroundColor: "#cdcdcd",
                                        borderRadius: "8px",
                                        transition:
                                          "background-color 0.2s ease-in-out",
                                      },
                                    },
                                  },
                                }}
                              >
                                {Array.from({ length: 12 }, (_, i) => (
                                  <MenuItem key={i * 5} value={i * 5}>
                                    {i * 5}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          {section.mediaUrl &&
                            (isImageUrl(section.mediaUrl) ? (
                              <img
                                src={section.mediaUrl}
                                alt="Section media"
                                style={{ height: "150px", width: "250px" }}
                              />
                            ) : (
                              <video
                                src={section.mediaUrl}
                                style={{ height: "170px", width: "300px" }}
                                controls
                              />
                            ))}
                          <Box
                            border={1}
                            borderColor="divider"
                            borderRadius={1}
                            p={2}
                            mb={2}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parseTextToHtml(
                                  formatContent(
                                    section?.editorState?.getCurrentContent()
                                  )
                                ),
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </Grid>
          <WorkingHoursPoup
            open={dialogOpen}
            workingHoursData={workingHoursData}
            handleClose={handleCloseDialog}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default Automation;
