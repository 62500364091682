import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import StepsComponent from "./StepsComponent";

const RightSidebar = ({
  setSelectedRadioValue,
  selectedRadioValue,
  steps,
  expandedStep,
  setExpandedStep,
  handleAddStep,
  handleStepDataChange,
  workflowData,
  handleEditorStateChange,
  handleVariablesChange,
  handleButtonsChange,
  handleSelectedListChange,
  handleSave,
  isWorkflowEditing,
}: any) => {
  const canAddStep = workflowData.every((step: any) => step.id !== "");
  return (
    <Box
      sx={{
        height: "75vh",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        padding: 1,
        overflowY: "scroll",
      }}
    >
      {steps?.map((step: number) => (
        <StepsComponent
          setSelectedRadioValue={setSelectedRadioValue}
          selectedRadioValue={selectedRadioValue}
          step={step}
          expandedStep={expandedStep}
          setExpandedStep={setExpandedStep}
          handleStepDataChange={handleStepDataChange}
          workflowData={workflowData}
          handleEditorStateChange={handleEditorStateChange}
          handleVariablesChange={handleVariablesChange}
          handleButtonsChange={handleButtonsChange}
          handleSelectedListChange={handleSelectedListChange}
          handleSave={handleSave}
          isWorkflowEditing={isWorkflowEditing}
        />
      ))}

      <Tooltip
        title={!canAddStep ? "Please save the previous step" : ""}
        placement="bottom"
      >
        <Typography
          onClick={canAddStep ? handleAddStep : undefined}
          style={{
            color: canAddStep ? "green" : "gray",
            fontWeight: "600",
            fontSize: "14px",
            cursor: canAddStep ? "pointer" : "not-allowed",
            marginTop: "10px",
            opacity: canAddStep ? 1 : 0.5,
          }}
        >
          + Add another step
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default RightSidebar;
