import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AnalyticsSvg from "../../assets/svgs/AnalyticsSvg";
import { useAppSelector } from "../../utils/redux-hooks";
import {
  // checkAdPerfomancePermission,
  checkAgentPerformancePermission,
  checkAnalyticsOverviewPermission,
  checkInboxAnalyticsPermission,
} from "../../utils/permissions";
import { GrDocumentPerformance } from "react-icons/gr";
import { AiOutlineInbox } from "react-icons/ai";
import { CgPerformance } from "react-icons/cg";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },

  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const AnalyticsSideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const getuserPermissionSlice = useAppSelector(
    (state:any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;

  const [activeSection, setActiveSection] = useState("");

  const hasAnalyticsOverviewPermission = checkAnalyticsOverviewPermission(
    getUserPermissionData?.analytics
  );
  const hasAgentPerformancePermission = checkAgentPerformancePermission(
    getUserPermissionData?.analytics
  );
  const hasInboxAnalyticsPermission = checkInboxAnalyticsPermission(
    getUserPermissionData?.analytics
  );
  // const hasAdPerfomancePermission = checkAdPerfomancePermission(
  //   getUserPermissionData?.analytics
  // );

  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/analytics/${path}`);
    setActiveSection(path);
  };

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    setActiveSection(path || "");
  }, [location.pathname]);
  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <AnalyticsSvg />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Analytics
        </Typography>
      </Box>

      <Box>
        {!hasAnalyticsOverviewPermission && (
          <Box
            m={2}
            // pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("overview") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("overview")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <GrDocumentPerformance /> &nbsp;Overview
            </Typography>
          </Box>
        )}
        {!hasAgentPerformancePermission && (
          <Box
            m={2}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("agent-performance")
                ? classes.activeItem
                : ""
            }`}
            onClick={() => handleNavigation("agent-performance")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
             <CgPerformance  />&nbsp;Agent Performance
            </Typography>
          </Box>
        )}
        {!hasInboxAnalyticsPermission && (
          <Box
            m={2}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("inbox-analytics")
                ? classes.activeItem
                : ""
            }`}
            onClick={() => handleNavigation("inbox-analytics")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <AiOutlineInbox  />&nbsp;Inbox Analytics
            </Typography>
          </Box>
        )}
        {/* {!hasAdPerfomancePermission && (
          <Box
            m={2}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("ad-performance") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("ad-performance")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <SiAnalogue />&nbsp;Ad Performance
            </Typography>
          </Box>
        )} */}
      </Box>
    </Grid>
  );
};

export default AnalyticsSideBar;
