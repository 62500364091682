import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import ButtonComponent from "../common/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { createTemplate } from "../../redux/slices/Templates/CreateTemplateSlice";
import LoadingComponent from "../common/LoadingComponent";
import { fetchTemplateById } from "../../redux/slices/Templates/TemplateById";
import TemplateDialogContent from "./TemplateForm/templateFormDialogContent";
import { updateTemplate } from "../../redux/slices/Templates/UpdateTemplateSlice";
import { ProcessButtonData, updateVariables } from "./TemplateForm/functions";
import useValidation from "./TemplateForm/validations";
import LanguagesList from "../../utils/languagesList.json";
import { fetchTemplateName } from "../../redux/slices/Templates/GetTemplateNameSlice";
import useDebouncedFetch from "../../utils/debounceHook";

const useStyles = makeStyles({
  dialogBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // cursor: "pointer",
  },
  draftButton: {
    backgroundColor: `${bgColors.white} !important`,
    width: "120px",
    color: `${bgColors.black} !important`,
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  button: {
    backgroundColor: `${bgColors.green} !important`,
    width: "120px",
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  cursor: {
    cursor: "pointer",
    fontSize: "10px",
  },
});

const buttonsArray = [
  { value: "None", label: "None" },
  { value: "PHONE_NUMBER", label: "Phone Number" },
  { value: "URL", label: "Website Link" },
  { value: "QUICK_REPLY", label: "Quick Reply" },
];
interface ButtonType {
  buttonType: string;
  buttonValue: string;
  countryCode: string;
  buttonName?: string;
}

interface TemplateData {
  businessId: any;
  userId: any;
  language: number;
  // mediaFile: string;
  redirectUrl: string[];
  urlButtonName: string[];
  quickReply: string[];
  callButtonName?: string;
  phoneNumber?: string;
  countryCode?: string;
  templateId?: string;
  draft?: boolean;
}
export interface TemplateState {
  templateName: string;
  category: number;
  language: number;
  subCategory: string;
  mediaType: number;
  mediaFile: any;
  header: string;
  body: string;
  callButtonName: string;
  phoneNumber: string;
  countryCode: string;
  urlButtonName: string[];
  redirectUrl: string[];
  quickReply: string[];
  footer: string;
  buttons: {
    buttonType: string;
    buttonValue: string;
    countryCode: string;
    buttonName?: any; // Optional property
  }[];
  variables: Array<{
    type: string;
    id: string;
    value: string;
    field: string;
    fallBackValue: string;
  }>;
}

interface FormErrors {
  [key: string]: string;
}

const TemplateComponent = ({ editObjectData, open, handleClose }: any) => {
  // console.log(editObjectData)
  const classes = useStyles();
  const maxLength = 1024;
  const dispatch = useAppDispatch();
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const headerRef = useRef<HTMLInputElement>(null);
  const urlButtonRef = useRef<HTMLInputElement>(null);

  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const templateNameSlice = useAppSelector(
    (state: any) => state?.templateNameData
  );
  const templateNameData = templateNameSlice?.data;
  const createTemplateSlice = useAppSelector(
    (state: any) => state?.createTemplateData
  );
  const uploadFileSlice = useAppSelector((state: any) => state?.uploadFileData);
  const updateTemplateSlice = useAppSelector(
    (state: any) => state?.updateTemplateData
  );
  const templateByIdSlice = useAppSelector(
    (state: any) => state?.templateByIdData
  );

  const [templateState, setTemplateState] = useState<TemplateState>({
    templateName: "",
    category: 1,
    language: 15,
    subCategory: "",
    mediaType: 1,
    mediaFile: null,
    header: "",
    body: "",
    callButtonName: "",
    phoneNumber: "",
    countryCode: "",
    urlButtonName: [],
    redirectUrl: [],
    quickReply: [],
    footer: "",
    buttons: [],
    variables: [],
  });
  // console.log(templateState)
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  // console.log(formErrors, "formErrors");
  const { isFormValid } = useValidation({
    templateState,
    formErrors,
    setFormErrors,
  });

  const cleanTemplateData = (data: any) => {
    const cleanedData: any = {};
    for (const key in data) {
      if (
        data[key] !== null &&
        data[key] !== undefined &&
        data[key] !== "" &&
        !(Array.isArray(data[key]) && data[key].length === 0)
      ) {
        cleanedData[key] = data[key];
      }
    }
    return cleanedData;
  };

  const handleSaveTemplate = async (draft: boolean) => {
    // console.log("noww");
    if (isFormValid()?.isValid) {
      // console.log("called");
      const { buttons, ...restTemplateState } = templateState;
      const { updatedState, quickReply, redirectUrl, urlButtonName } =
        ProcessButtonData(restTemplateState, buttons);

      const data: TemplateData = {
        ...updatedState,
        businessId: userData?.companyId,
        userId: userData?.userId,
        language: templateState?.language,
        redirectUrl,
        urlButtonName,
        quickReply,
      };
      // console.log("data ....", data);
      const cleanedData: any = cleanTemplateData(data);
      // Remove 'variables' from cleanedData
      delete cleanedData.variables;

      try {
        let apiResponse: any;
        if (
          editObjectData?.canEdit &&
          templateByIdSlice?.data[0]?.status !== 4
        ) {
          // Edit case
          // console.log("cleaned data", cleanedData);
          cleanedData.templateId = editObjectData?.templateId;
          apiResponse = await dispatch(updateTemplate(cleanedData));
        } else {
          // Create case
          cleanedData.draft = draft;

          if (editObjectData?.templateId) {
            cleanedData.templateId = editObjectData.templateId;
          }

          // Dispatch the action and wait for the result
          apiResponse = await dispatch(createTemplate(cleanedData));
        }
        // console.log("apiResponse", apiResponse);

        if (apiResponse?.meta?.requestStatus === "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: apiResponse?.payload?.message,
            })
          );
          handleCloseAll();
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: apiResponse?.payload,
            })
          );
        }
      } catch (error: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: error?.message,
          })
        );
      }
    }
  };

  const handleCloseAll = () => {
    setFormErrors({
      templateName: "",
      category: "",
      subCategory: "",
      mediaType: "",
      header: "",
      mediaFile: "",
      body: "",
    });
    setTemplateState({
      templateName: "",
      category: 1,
      language: 15,
      subCategory: "",
      mediaType: 1,
      mediaFile: null,
      header: "",
      body: "",
      footer: "",
      callButtonName: "",
      phoneNumber: "",
      countryCode: "",
      urlButtonName: [],
      redirectUrl: [],
      quickReply: [],
      buttons: [],
      variables: [],
    });
    handleClose();
  };

  const checkTemplateName = useDebouncedFetch(fetchTemplateName, 0);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    // console.log("Check", name, value);

    if (name === "templateName") {
      const data = {
        businessId: userData?.companyId,
        templateName: value,
      };
      checkTemplateName(data);
    }
    if (name === "buttons") {
      if (value !== "None") {
        setTemplateState((prevState) => ({
          ...prevState,
          [name]: [
            {
              buttonType: value,
              buttonValue: "",
              countryCode: "",
              buttonName: "", // Optional property
            },
          ],
        }));
      } else {
        setTemplateState((prevState) => ({
          ...prevState,
          [name]: [],
        }));
      }
    } else {
      setTemplateState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      // setFormErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [name]: "",
      // }));

      // Filter out variables not present in the body/header text
      if (name === "body" || name === "header") {
        const variableIds: any = value.match(/{{(\d+)}}/g) || [];
        const updatedVariables =
          templateState.variables?.filter(
            (variable) =>
              variable.type !== name || variableIds.includes(variable.id)
          ) || [];
        setTemplateState((prevState) => ({
          ...prevState,
          variables: updatedVariables,
        }));
      }
      // Perform validation
      const { isFormValid } = useValidation({
        templateState: { ...templateState, [name]: value },
        formErrors,
        setFormErrors,
      });

      setFormErrors(isFormValid()?.errors);
    }
  };

  const handleAddVariable = (
    type: string,
    urlButtonIndex?: number,
    buttonIndex?: number
  ) => {
    // Get current template state
    const bodyText = templateState?.body || "";
    const headerText = templateState?.header || "";
    const urlButtons =
      templateState?.buttons?.filter(
        (button: any) => button?.buttonType === "URL"
      ) || [];

    // Determine input references and positions based on type
    let input, startPos, endPos, newText;

    if (type === "body") {
      input = bodyRef?.current;
      newText = bodyText;
    } else if (type === "header") {
      input = headerRef?.current;
      newText = headerText;
    } else if (type === "url Button") {
      // Check if a specific URL button is targeted
      if (
        typeof buttonIndex === "number" &&
        urlButtons[urlButtonIndex ? urlButtonIndex : 0]
      ) {
        input = urlButtonRef?.current;
        newText = urlButtons[urlButtonIndex ? urlButtonIndex : 0].buttonValue;
      }
    }
    // console.log("newText", newText);

    // If input is undefined, exit early
    if (!input) return;

    // Assign default values to startPos and endPos if they are null or undefined
    startPos = input.selectionStart ?? 0;
    endPos = input.selectionEnd ?? 0;

    // Update variables with the proper count for the type
    const { newVariables, newCount } = updateVariables(
      type,
      templateState,
      urlButtonIndex
    );

    const variableText =
      type === "url Button" ? `{{${newCount}}}` : ` {{${newCount}}} `;

    // Update the appropriate text based on type
    const startPosIndex = startPos || 0;
    const endPosIndex = endPos || 0;

    // Construct the new text with the variable inserted at the correct position
    const updatedText =
      newText?.substring(0, startPosIndex) +
      variableText +
      newText?.substring(endPosIndex, newText?.length);

    // Set the new template state
    setTemplateState((prevState) => ({
      ...prevState,
      body: type === "body" ? updatedText : prevState.body,
      header: type === "header" ? updatedText : prevState.header,
      buttons:
        type === "url Button"
          ? prevState.buttons.map((button: any, index: number) =>
              button.buttonType === "URL" && index === buttonIndex
                ? { ...button, buttonValue: updatedText }
                : button
            )
          : prevState.buttons,
      variables: newVariables,
    }));
    // console.log("templateState", newVariables, templateState);
  };

  const handleHeaderMediaChange = (file: any, event: File | null) => {
    // console.log("file", file, event);
    // const file = event.target.files && event.target.files[0];
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const allowedVideoTypes = ["video/mp4", "video/avi", "video/mpeg"];
    const allowedDocumentTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    let isValidFile = false;
    if (event) {
      switch (templateState?.mediaType) {
        case 3:
          isValidFile = allowedImageTypes?.includes(event.type);
          break;
        case 4:
          isValidFile = allowedVideoTypes?.includes(event.type);
          break;
        case 5:
          isValidFile = allowedDocumentTypes?.includes(event.type);
          break;
        default:
          isValidFile = false;
      }

      if (isValidFile) {
        setTemplateState((prevState: any) => ({
          ...prevState,
          mediaFile: file?.payload,
        }));
      } else {
        alert(
          `Only ${
            templateState?.mediaType === 3
              ? allowedImageTypes
              : templateState?.mediaType === 4
              ? allowedVideoTypes
              : templateState?.mediaType === 5
              ? allowedDocumentTypes
              : ""
          } files are allowed.`
        );
        file = null; // Clear the input field if the file is invalid
      }
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      mediaFile: "",
    }));
  };

  const handleMediaSelectionChange = (event: any) => {
    setTemplateState((prevState: any) => ({
      ...prevState,
      mediaType: Number(event.target.value),
      mediaFile: "",
      header: "",
    }));
  };

  const handleEmojiClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setEmojiPopoverOpen(true);
  };

  const handleEmojiSelect = (emoji: string) => {
    const { current } = bodyRef;
    if (current) {
      const { selectionStart, selectionEnd } = current;
      const currentBody = templateState?.body;
      const newBody =
        currentBody?.slice(0, selectionStart) +
        emoji +
        currentBody?.slice(selectionEnd);

      setTemplateState((prevState) => ({
        ...prevState,
        body: newBody,
      }));
      // console.log("new body", newBody);

      // Set cursor position after the inserted emoji
      setTimeout(() => {
        bodyRef?.current?.focus();
        bodyRef?.current?.setSelectionRange(
          selectionStart + emoji?.length,
          selectionStart + emoji?.length
        );
      }, 0);
    }
  };

  const handleCloseEmojiPopover = () => {
    setEmojiPopoverOpen(false);
  };

  const addButton = () => {
    setTemplateState((prevState) => ({
      ...prevState,
      buttons: [
        ...prevState.buttons,
        {
          buttonType: "None",
          buttonValue: "",
          countryCode: "",
          buttonName: "",
        },
      ],
    }));
  };

  const removeButton = (index: number) => {
    // console.log("index: " + index);
    setTemplateState((prevState) => ({
      ...prevState,
      buttons: prevState.buttons.filter((_, i) => i !== index),
    }));
  };

  const updateButton = (index: number, updatedButton: ButtonType) => {
    // console.log("updated Button: ", updatedButton);
    setTemplateState((prevState) => ({
      ...prevState,
      buttons: prevState.buttons.map((button, i) =>
        i === index ? updatedButton : button
      ),
    }));
  };

  useEffect(() => {
    if (editObjectData?.templateId) {
      const params = {
        userId: userData?.userId,
        businessId: userData?.companyId,
        templateId: editObjectData?.templateId,
      };
      dispatch(fetchTemplateById(params));
    }
  }, [editObjectData]);

  let getLangValue = (code: any) => {
    return LanguagesList.find((obj) => obj.code === code)?.value;
  };

  useEffect(() => {
    if (editObjectData?.canEdit && templateByIdSlice?.data?.length !== 0) {
      const templateData = templateByIdSlice?.data[0];
      // console.log("editObjectData:.....", templateByIdSlice?.data[0])
      let langVal = getLangValue(templateData?.languageCode);
      setTemplateState({
        templateName: templateData?.templateName,
        category: templateData?.category,
        language: langVal || 15,
        subCategory: templateData?.subCategory,
        mediaType: templateData?.mediaType,
        mediaFile: templateData?.mediaFile,
        header: templateData?.header,
        body: templateData?.body,
        callButtonName: templateData?.callButtonName,
        phoneNumber: templateData?.phoneNumber,
        countryCode: templateData?.countryCode,
        urlButtonName: templateData?.urlButtonName,
        redirectUrl: templateData?.redirectUrl,
        quickReply: templateData?.quickReply,
        footer: templateData?.footer,
        buttons: templateData?.buttons,
        variables: templateData?.variables,
      });
    }
  }, [templateByIdSlice]);

  useEffect(() => {
    if (
      templateNameSlice?.status === "failed" &&
      templateState?.templateName &&
      templateNameData
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        templateName: templateNameData !== null ? templateNameData : "", // Assume templateNameData contains the validation response
      }));
    }
  }, [templateNameData]);

  const phoneNumberButtonCount = templateState?.buttons?.filter(
    (button) => button?.buttonType === "PHONE_NUMBER"
  )?.length;
  const urlButtonCount = templateState?.buttons?.filter(
    (button) => button?.buttonType === "URL"
  )?.length;
  const replyButtonCount = templateState?.buttons?.filter(
    (button) => button?.buttonType === "QUICK_REPLY"
  )?.length;

  const TemplateSvg = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5455 1.10645H6.45455C3.44208 1.10645 1 3.54853 1 6.56099V10.6064H2H10.5H13.5H30H31V6.56099C31 3.54853 28.5579 1.10645 25.5455 1.10645ZM10.5 13.6064H2H1V25.6519C1 28.6644 3.44208 31.1064 6.45455 31.1064H10.5V30.1064V13.6064ZM13.5 31.1064V30.1064V13.6064H30H31V25.6519C31 28.6644 28.5579 31.1064 25.5455 31.1064H13.5Z"
          fill="#4B5A5A"
        />
      </svg>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{
        style: {
          minWidth: "75%",
          minHeight: "90%",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>
        <Box mx={1} className={classes.dialogBox}>
          <Typography
            // variant="h6"
            sx={{
              color: "#000000",
              fontWeight: "600",
              fontSize: { xs: "14px", md: "20px" },
              display: "flex",
            }}
          >
            <span style={{ marginTop: "3px" }}>
              <TemplateSvg />
            </span>{" "}
            &nbsp;{editObjectData?.canEdit ? "Edit" : "Create"}&nbsp;Template
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      {templateByIdSlice?.status === "loading" ? (
        <Box sx={{ height: "70vh" }}>
          <LoadingComponent height="100%" color={bgColors?.blue} />
        </Box>
      ) : (
        <>
          <DialogContent>
            <TemplateDialogContent
              canEdit={
                editObjectData?.canEdit ? editObjectData?.canEdit : false
              }
              templateData={
                editObjectData?.canEdit && templateByIdSlice?.data?.length > 0
                  ? templateByIdSlice?.data[0]
                  : null
              }
              templateState={templateState}
              templateNameSlice={templateNameSlice}
              setTemplateState={setTemplateState}
              formErrors={formErrors}
              handleChange={handleChange}
              handleMediaSelectionChange={handleMediaSelectionChange}
              handleHeaderMediaChange={handleHeaderMediaChange}
              handleEmojiClick={handleEmojiClick}
              handleEmojiSelect={handleEmojiSelect}
              handleCloseEmojiPopover={handleCloseEmojiPopover}
              emojiPopoverOpen={emojiPopoverOpen}
              anchorEl={anchorEl}
              bodyRef={bodyRef}
              headerRef={headerRef}
              urlButtonRef={urlButtonRef}
              maxLength={maxLength}
              phoneNumberButtonCount={phoneNumberButtonCount}
              urlButtonCount={urlButtonCount}
              replyButtonCount={replyButtonCount}
              addButton={addButton}
              removeButton={removeButton}
              updateButton={updateButton}
              buttonsArray={buttonsArray}
              handleAddVariable={handleAddVariable}
            />
          </DialogContent>
          {createTemplateSlice?.status === "loading" ||
          uploadFileSlice?.status === "loading" ||
          updateTemplateSlice?.status === "loading" ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <DialogActions>
              {(editObjectData?.canEdit === false ||
                (editObjectData?.canEdit &&
                  templateByIdSlice?.data?.length > 0)) && (
                <Box
                  display="flex"
                  // width={
                  //   // !editObjectData?.canEdit &&
                  //   templateByIdSlice?.data[0]?.status !== 4 ? 350 : 250
                  // }
                  minWidth={300}
                  justifyContent="space-between"
                  mx={4}
                >
                  {((editObjectData?.canEdit &&
                    templateByIdSlice?.data[0]?.status !== 2) ||
                    !editObjectData?.canEdit) && (
                    <ButtonComponent
                      onClick={() => handleSaveTemplate(true)}
                      title="Save as Draft"
                      className={classes.draftButton}
                    />
                  )}
                  <Box width={30}></Box>
                  <ButtonComponent
                    onClick={() => handleSaveTemplate(false)}
                    title="Submit"
                    className={classes.button}
                  />
                </Box>
              )}
              <br />
              <br />
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default TemplateComponent;
