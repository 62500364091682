import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
} from "@mui/material";

const WidgetSnippetDialog = ({ open, onClose, widgetId }: any) => {
//   console.log(widgetId, "widgetId");
  const [copied, setCopied] = useState(false);

  const snippetCode =
    "<script\n" +
    '  type="text/javascript"\n' +
    '  src="https://d9mkw6s8thqya7.cloudfront.net/integration-plugin.js"\n' +
    '  id="aisensy-wa-widget"\n' +
    '  widget-id="' +
    widgetId +
    '"\n' +
    "></script>";

  const handleCopySnippet = () => {
    navigator.clipboard.writeText(snippetCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Here is your Widget Snippet</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Copy and paste this snippet within the <code>&lt;head&gt;</code> tag
          of your webpage.
        </Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={snippetCode}
          InputProps={{
            readOnly: true,
            sx: {
              fontFamily: "monospace",
              // bgcolor: "action.hover"
            },
          }}
          sx={{ my: 2 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          {!copied ? (
            <Typography variant="body2" color="primary">
              FREE !!- Track clicks & much more,{" "}
              <a href="#" style={{ color: "inherit" }}>
                Sign Up
              </a>{" "}
              now
            </Typography>
          ) : (
            <Typography variant="body2" color="green">
              Snippet has been copied to clipboard !!
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleCopySnippet}
            sx={{ textTransform: "none" }}
          >
            {copied ? "Copied!" : "Copy Snippet"}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WidgetSnippetDialog;
