import React from "react";
import {
  Grid,
  Box,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../../utils/bgColors";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import FileUpload from "../../common/FileUpload";
import EmojiPopover from "../../InboxComponents/inboxDetailsComponents/EmojiPicker";
import ActionButtonsComponent from "./actionButtons";
import DevicePreviewComponent from "./devicePreview";
import { RefObject, useEffect, useRef, useState } from "react";
import { EditorState, ContentBlock, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";
import { DraftEditorComponent } from "../../common/DraftEditorComponent";
// import { toastActions } from "../../../redux/slices/toastSlice";
// import { useAppDispatch } from "../../../redux/slices/redux-hooks";
import { reactDraftWysiwygToolbarOptionsarticle } from "../../../utils/react-draft-wysiwyg-options";
// import { useAppDispatch } from "../../../utils/redux-hooks";
// import { stateFromHTML } from 'draft-js-import-html';
import LanguagesList from "../../../utils/languagesList.json";
import { formatContent, parseTextToDraft } from "../../../utils/functions";
import { countTemplateVariables } from "./functions";

const useStyles = makeStyles({
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  variable: {
    color: `${bgColors.green} !important`,
    fontWeight: "500 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  formControl: {
    display: "flex",
    alignItems: "center",
  },
});

const mediaTypes = [
  { value: 1, label: "None" },
  { value: 2, label: "Text" },
  { value: 3, label: "Image" },
  { value: 4, label: "Video" },
  { value: 5, label: "Document" },
];

interface ButtonType {
  buttonType: string;
  buttonValue: string;
  countryCode: string;
  buttonName?: string;
}
interface TemplateState {
  templateName: string;
  category: number;
  language: number;
  subCategory: string;
  mediaType: number;
  mediaFile: any;
  header: string;
  body: string;
  countryCode: string;
  callButtonName: any;
  phoneNumber: any;
  urlButtonName: any;
  redirectUrl: any;
  quickReply: any;
  footer: string;
  buttons: {
    buttonType: string;
    buttonValue: string;
    countryCode: string;
    buttonName?: any; // Optional property
  }[];
  variables: Array<{
    type: string;
    id: string;
    value: string;
    field: string;
    fallBackValue: string;
  }>;
}

interface TemplateDialogContentProps {
  canEdit?: boolean;
  templateData: any; // Assuming TemplateState is defined elsewhere
  templateNameSlice: any; // Assuming TemplateState is defined elsewhere
  setTemplateState: any;
  templateState: TemplateState; // Assuming TemplateState is defined elsewhere
  formErrors: Record<string, string>;
  handleChange: (event: any) => void;
  handleMediaSelectionChange: (event: any) => void;
  handleHeaderMediaChange: (file: string | null, event: File | null) => void;
  handleEmojiClick: (event: any) => void;
  handleEmojiSelect: (emoji: string) => void;
  handleCloseEmojiPopover: () => void;
  handleAddVariable: (
    type: string,
    urlButtonIndex?: number,
    buttonIndex?: number
  ) => void;
  emojiPopoverOpen: boolean;
  anchorEl: HTMLDivElement | null;
  bodyRef: RefObject<HTMLTextAreaElement>;
  headerRef: RefObject<HTMLInputElement>;
  urlButtonRef: RefObject<HTMLInputElement>;
  maxLength: number;
  phoneNumberButtonCount: number;
  urlButtonCount: number;
  replyButtonCount: number;
  addButton: () => void;
  removeButton: (index: number) => void;
  updateButton: (index: number, updatedButton: ButtonType) => void;
  buttonsArray: { value: string; label: string }[];
}

const TemplateDialogContent: React.FC<TemplateDialogContentProps> = ({
  canEdit,
  templateData,
  templateNameSlice,
  setTemplateState,
  templateState,
  formErrors,
  handleChange,
  handleMediaSelectionChange,
  handleHeaderMediaChange,
  handleEmojiSelect,
  handleCloseEmojiPopover,
  handleAddVariable,
  emojiPopoverOpen,
  anchorEl,
  bodyRef,
  headerRef,
  urlButtonRef,
  maxLength,
  phoneNumberButtonCount,
  urlButtonCount,
  replyButtonCount,
  addButton,
  removeButton,
  updateButton,
  buttonsArray,
}) => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();

  // Get the count of variables
  const { headerVariablesCount, bodyVariablesCount, urlButtonVariablesCount } =
    countTemplateVariables(templateState);

  const chatAreaRef = useRef<HTMLDivElement>(null);

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromText(templateState?.body)));
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(parseTextToDraft(templateState?.body || ""))
  );
  const [called, setCalled] = useState(false);
  // const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromText('Default text heree')));
  const [file] = useState<any>(null);

  const handleEditorDelayedStateChange = (editorState: EditorState) => {
    const formattedContent = formatContent(editorState.getCurrentContent());
    // console.log(formattedContent)
    const event = {
      target: {
        name: "body", // or 'header' depending on which field you are editing
        value: formattedContent,
      },
    };
    handleChange(
      event as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    );

    setEditorState(editorState);
  };

  const blockRendererFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: Media,
        editable: false,
      };
    }
    return null;
  };

  const Media = (props: any) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src, type } = entity.getData();
    let media;
    if (type === "IMAGE") {
      media = (
        <img
          src={src}
          alt="uploaded"
          style={{ height: "100px", width: "100px" }}
        />
      );
    } else if (type === "VIDEO") {
      media = (
        <video src={src} controls style={{ height: "100px", width: "300px" }} />
      );
    }
    return media;
  };

  const handleSaveInboxSettings = async () => {
    // const formatTime = (hours: number, minutes: number): string => {
    //   const pad = (num: number) => String(num).padStart(2, "0");
    //   return `${pad(hours)}:${pad(minutes)}:00`;
    // };
    // const delay = feature === 2 ? formatTime(selectedDelayHours, selectedDelayMinutes) : null;
    // try {
    //   const data = {
    //     businessId: getLoginData?.companyId,
    //     userId: getLoginData?.userId,
    //     inboxSettings: {
    //       feature: feature,
    //       message: feature === 0 ?  formatContent(editorState.getCurrentContent()) :
    //               feature === 1 ? formatContent(welcomEditorState.getCurrentContent()):
    //               formatContent(delayedEditorState.getCurrentContent()),
    //       enabled: outOfOfficeEnable,
    //       delay: delay,
    //       mediaUrl: fileUrl ? fileUrl : null,
    //     }
    //   }
    //   const getRes = await dispatch(updateInboxSettings(data))
    //   if (getRes?.meta.requestStatus === "fulfilled") {
    //     dispatch(toastActions.setToaster({
    //       type: "success",
    //       message: (getRes?.payload?.message || "Inbox settings updated successfully"),
    //     }))
    //     dispatch(getInboxSettings({ businessId: getLoginData?.companyId, userId: getLoginData?.userId, feature: feature }))
    //     setEditInbox(false)
    //     setEditWelcomeBox(false)
    //   } else {
    //     dispatch(toastActions.setToaster({
    //       type: "error",
    //       message: "Something went wrong",
    //     }))
    //   }
    // } catch (error) {
    //   console.log("error", error)
    // }
  };

  const getNextVariableCount = () => {
    const body = templateState.body;
    // console.log(body);

    // Find all variables in the body text
    const variableMatches = body.match(/\{\{(\d+)\}\}/g);

    if (!variableMatches) return 1; // If no variables found, start with 1

    // Extract the numbers from the variables and convert to integers
    const variableNumbers = variableMatches.map((match) =>
      parseInt(match.replace(/\{\{|\}\}/g, ""), 10)
    );

    // Find the maximum number
    const maxCount = Math.max(...variableNumbers);

    // Return the next number after the maximum
    return maxCount + 1;
  };

  const handleEditorAddVariable = () => {
    // console.log("called")
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Get the next variable count
    const newCount = getNextVariableCount();

    const newVariable = {
      type: "body",
      id: `{{${newCount}}}`,
      value: "",
      field: "",
      fallBackValue: "",
    };

    // Construct the new variables array
    // const newVariables = [...templateState.variables, newVariable];
    const newVariables = [...(templateState?.variables || []), newVariable];

    const variableText = ` {{${newCount}}} `;

    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      variableText
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    setEditorState(newEditorState);

    // Update the template state
    const formattedContent = formatContent(newContentState);
    setTemplateState((prevState: any) => ({
      ...prevState,
      body: formattedContent,
      variables: newVariables,
    }));
  };

  useEffect(() => {
    if (canEdit && templateState?.body && !called) {
      setEditorState(
        EditorState?.createWithContent(parseTextToDraft(templateState?.body))
      );
      setCalled(true);
    }
  }, [canEdit, templateState?.body]);

  return (
    <Grid container justifyContent="space-around" ref={chatAreaRef}>
      <Grid item xs={12} md={6} mt={0}>
        <Box mb={{ xs: 1, md: 2 }}>
          <InputLabel
            className={classes.blackColor}
            sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
          >
            Template Name*
            <Tooltip
              title={
                <div>
                  The template name should be unique from the existing
                  templates.
                </div>
              }
              arrow
            >
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <TextFieldWithBorderComponent
            size="small"
            fullWidth
            name="templateName"
            disabled={canEdit && templateData?.status !== 4}
            placeholder=""
            label="Enter template name"
            value={templateState?.templateName?.toLocaleLowerCase() || ""}
            onChange={handleChange}
            sx={{
              "& input": {
                fontSize: "14px", // Set input font size
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Set border radius
              },
            }}
            error={!!formErrors?.templateName}
            helperText={
              templateNameSlice?.status === "loading" ||
              templateNameSlice?.status === "idle" ||
              (canEdit === true && templateData?.status !== 4) ||
              (canEdit === true &&
                templateData?.status === 4 &&
                templateState?.templateName === templateData?.templateName)
                ? ""
                : formErrors?.templateName ||
                  (templateState?.templateName !== "" && (
                    <span style={{ color: bgColors?.green }}>
                      {templateNameSlice?.data?.message}
                    </span>
                  ))
            }
            InputProps={{
              sx: { fontSize: 14 },
              endAdornment: (
                <>
                  {templateNameSlice?.status === "loading" && (
                    <CircularProgress size={20} />
                  )}
                </>
              ),
            }}
          />
        </Box>
        <Box display="flex" width="100%" mb={{ xs: 1, md: 2 }}>
          <Box width="50%" mr={0}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
            >
              Category*
              <Tooltip
                title={
                  <>
                    <div>
                      1. Marketing conversation: Business-initiated
                      conversations to market a product or service to customers,
                      such as relevant offers to customers who have opted in.
                    </div>
                    <div>
                      2. Utility conversations: Business-initiated conversations
                      relating to a transaction, including post-purchase
                      notifications and recurring billing statements to
                      customers who have opted in.
                    </div>
                    {/* <div>
                      3. Authentication: Business-initiated conversations that
                      enable businesses to authenticate users with one-time
                      passcodes at multiple steps during the login process, such
                      as account registration, account recovery, and integrity
                      challenges.
                    </div> */}
                  </>
                }
                arrow
              >
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <FormControl fullWidth size="small" error={!!formErrors?.category}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={canEdit && templateData?.status !== 4}
                value={templateState?.category}
                name="category"
                label="Category"
                inputProps={{ style: { fontSize: 14 } }}
                onChange={handleChange}
                sx={{
                  fontSize: 14,
                  borderRadius: "8px",
                  // fontWeight: "600",
                }}
              >
                <MenuItem
                  value={1}
                  key={1}
                  sx={{ fontSize: { xs: 10, md: 12 } }}
                >
                  Marketing
                </MenuItem>
                <MenuItem
                  value={2}
                  key={2}
                  sx={{ fontSize: { xs: 10, md: 12 } }}
                >
                  Utility
                </MenuItem>
                {/* <MenuItem
                  value={3}
                  key={3}
                  sx={{ fontSize: { xs: 10, md: 12 } }}
                >
                  Authentication
                </MenuItem> */}
              </Select>
              {formErrors?.category && (
                <FormHelperText>{formErrors?.category}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box width="50%" ml={1}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
            >
              Language*
              <Tooltip
                title={
                  <>
                    <div>
                      1. Choose relevant language to reduce the probability of
                      rejection for your template.
                    </div>
                  </>
                }
                arrow
              >
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <FormControl fullWidth size="small" error={!!formErrors?.language}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Language
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={canEdit && templateData?.status !== 4}
                value={templateState?.language}
                name="language"
                label="Language"
                inputProps={{ style: { fontSize: 14 } }}
                onChange={handleChange}
                sx={{
                  fontSize: 14,
                  borderRadius: "8px",
                  // fontWeight: "600",
                }}
              >
                {LanguagesList &&
                  LanguagesList?.map((item: any, index: number) => (
                    <MenuItem
                      value={item?.value}
                      key={index}
                      sx={{ fontSize: 14 }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight:
                            item?.value === templateState?.language
                              ? "normal"
                              : "normal",
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </MenuItem>
                  ))}
              </Select>
              {formErrors?.language && (
                <FormHelperText>{formErrors?.language}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
        <Box width="100%" mb={{ xs: 1, md: 2 }}>
          <InputLabel
            className={classes.blackColor}
            sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
          >
            Sub-category*
            <Tooltip
              title={<div>1.Sub Category must not exceed 30 characters.</div>}
              arrow
            >
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <TextFieldWithBorderComponent
            variant="outlined"
            // required
            size="small"
            fullWidth
            value={templateState?.subCategory}
            onChange={handleChange}
            name="subCategory"
            placeholder=""
            label="Enter sub-category"
            sx={{
              "& input": {
                // fontWeight: "600",
              },
            }}
            error={!!formErrors?.subCategory}
            helperText={formErrors?.subCategory}
          />
        </Box>
        <Box
          mb={templateState?.mediaFile ? { xs: 3, md: 4 } : { xs: 1, md: 2 }}
        >
          <InputLabel
            className={classes.blackColor}
            sx={{ fontSize: { xs: 12, md: 14 } }}
          >
            Header*
            {templateState?.mediaType === 2 && (
              <Tooltip
                title={
                  <>
                    <div>
                      1. Minimum one letter or word must be present in between
                      any two variables.
                    </div>
                    <div>
                      2. Header cannot be started or ended with a variable.
                    </div>
                    <div>3. Only one variable is allowed in header.</div>
                    <div> 4.Header text must not exceed 60 characters.</div>
                    {/* <div>
                      5.Header should not contain double quotes. Use single
                      quotes instead.
                    </div> */}
                  </>
                }
                arrow
              >
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </InputLabel>
          <RadioGroup
            row
            value={templateState?.mediaType}
            onChange={handleMediaSelectionChange}
          >
            {mediaTypes?.map((option) => (
              <FormControlLabel
                key={option?.value}
                value={option?.value}
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: 12 }}>{option.label}</Typography>
                }
                disabled={
                  canEdit === true &&
                  templateData?.mediaType >= 3 &&
                  canEdit === true &&
                  templateData?.mediaType <= 5 &&
                  (option?.value === 1 || option?.value === 2)
                }
              />
            ))}
          </RadioGroup>
          {templateState?.mediaType === 2 && (
            <>
              <TextFieldWithBorderComponent
                inputRef={headerRef}
                variant="outlined"
                type="text"
                size="small"
                name="header"
                value={templateState?.header}
                onChange={handleChange}
                fullWidth
                label="Enter header text"
                placeholder=""
                sx={{
                  "& input": {
                    // fontWeight: "600",
                  },
                }}
                error={!!formErrors?.header}
                helperText={formErrors?.header}
              />
              <Typography
                variant="h5"
                sx={{
                  visibility: headerVariablesCount >= 1 ? "hidden" : "visible",
                  width: 115,
                }}
                className={classes.variable}
                onClick={() => {
                  if (headerVariablesCount < 1) {
                    handleAddVariable("header");
                  }
                }}
              >
                + Add Variables
              </Typography>
            </>
          )}
          {(templateState?.mediaType === 3 ||
            templateState?.mediaType === 4 ||
            templateState?.mediaType === 5) && (
            <>
              <FileUpload
                fileType={templateState?.mediaType}
                selectedFiles={templateState?.mediaFile}
                handleMediaChange={handleHeaderMediaChange}
                isFileValid={true}
              />
              {formErrors?.mediaFile && (
                <FormHelperText style={{ color: "red" }}>
                  {formErrors?.mediaFile}
                </FormHelperText>
              )}
            </>
          )}
        </Box>
        <InputLabel
          className={classes.blackColor}
          sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
        >
          Body*
          <Tooltip
            title={
              <>
                <div>
                  1. Minimum one letter or word must be present in between any
                  two variables.
                </div>
                <div>2. Body cannot be started or ended with a variable.</div>
                <div>
                  3. Total variables count should be less than half of the words
                  in the body.
                </div>
                <div>4.Body must not exceed 1024 characters.</div>
              </>
            }
            arrow
          >
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </InputLabel>
        <FormControl fullWidth error={!!formErrors.body}>
          <div style={{ position: "relative" }}>
            <EmojiPopover
              open={emojiPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleCloseEmojiPopover}
              onEmojiSelect={handleEmojiSelect}
            />
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                color:
                  templateState?.body?.length > maxLength
                    ? "error.main"
                    : "inherit",
                right: 8,
                top: 8,
              }}
            >
              {templateState?.body?.length} / {maxLength}
            </Typography>
            <DraftEditorComponent
              editorState={editorState}
              handleEditorStateChange={handleEditorDelayedStateChange}
              handleSaveInboxSettings={handleSaveInboxSettings}
              reactDraftWysiwygToolbarOptionsarticle={
                reactDraftWysiwygToolbarOptionsarticle
              }
              blockRendererFn={blockRendererFn}
              handleAddVariable={handleEditorAddVariable}
              file={file}
              bodyRef={bodyRef}
              chatAreaRef={chatAreaRef}
              // handleFileUpload={handleFileUpload}
              borderColor={formErrors.body ? "red" : "rgb(205, 205, 205)"}
            />
            {formErrors.body && (
              <FormHelperText>{formErrors.body}</FormHelperText>
            )}
          </div>
        </FormControl>
        <Box mt={{ xs: 1, md: 2 }} mb={2}>
          <InputLabel
            className={classes.blackColor}
            sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
          >
            Footer
            <Tooltip
              title={<div>Footer text must not exceed 60 characters.</div>}
              arrow
            >
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <TextFieldWithBorderComponent
            size="small"
            fullWidth
            name="footer"
            placeholder=""
            label="Enter footer message"
            value={templateState?.footer || ""}
            onChange={handleChange}
            sx={{
              "& input": {
                // fontWeight: "600",
              },
            }}
            error={!!formErrors?.footer}
            helperText={formErrors?.footer}
          />
        </Box>
        {templateState?.buttons?.length > 0 ? (
          <>
            {templateState?.buttons?.map((item, index) => (
              <Box key={index}>
                <ActionButtonsComponent
                  formErrors={formErrors}
                  // setFormErrors={setFormErrors}
                  buttonType={item?.buttonType}
                  buttonTypesList={buttonsArray}
                  buttonValue={item?.buttonValue}
                  countryCode={item?.countryCode}
                  buttonName={item?.buttonName}
                  index={index}
                  phoneNumberCount={phoneNumberButtonCount}
                  urlsCount={urlButtonCount}
                  repliesCount={replyButtonCount}
                  onRemoveButton={() => removeButton(index)}
                  updateButton={(updatedButton: ButtonType) =>
                    updateButton(index, updatedButton)
                  }
                  handleAddVariable={handleAddVariable}
                  urlButtonVariablesCount={urlButtonVariablesCount}
                  urlButtonRef={urlButtonRef}
                  setTemplateState={setTemplateState}
                  templateState={templateState}
                />
              </Box>
            ))}
            <Typography
              onClick={addButton}
              sx={{
                fontSize: 12,
                cursor: "pointer",
                color: bgColors?.green,
                textTransform: "none",
                width: 85,
              }}
            >
              + Add Button
            </Typography>
          </>
        ) : (
          <Box width="50%" mr={1}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
            >
              Buttons
              <Tooltip
                title="Add buttons in this order: 1. Phone number 2. Website links 3. Quick replies"
                arrow
              >
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Select Button
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={templateState?.buttons}
                name="buttons"
                label="Select Button"
                inputProps={{ style: { fontSize: 14 } }}
                onChange={handleChange}
                sx={{
                  fontSize: 14,
                  borderRadius: "8px",
                  // fontWeight: "600",
                }}
              >
                {buttonsArray?.map((item, index) => (
                  <MenuItem
                    value={item?.value}
                    key={index}
                    sx={{ fontSize: { xs: 10, md: 12 } }}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <DevicePreviewComponent
          // canEdit={canEdit ? canEdit : false}
          header={templateState?.header}
          body={templateState?.body}
          footer={templateState?.footer}
          mediaType={templateState?.mediaType}
          mediaFile={templateState?.mediaFile ? templateState?.mediaFile : null}
          buttons={templateState?.buttons}
        />
      </Grid>
    </Grid>
  );
};

export default TemplateDialogContent;
