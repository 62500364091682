import React, { useEffect } from "react";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomResponseComponent from "./CustomResponseComponent";
import TriggerWebhookPage from "./triggerWebhook";
import { useAppSelector } from "../../../utils/redux-hooks";

const RadioOptions = [
  { value: 1, label: "Select a response" },
  { value: 2, label: "Custom response" },
  { value: 3, label: "Trigger Webhook" },
];

const StepsComponent = ({
  setSelectedRadioValue,
  selectedRadioValue,
  step,
  expandedStep,
  setExpandedStep,
  handleStepDataChange,
  workflowData,
  handleEditorStateChange,
  handleVariablesChange,
  handleButtonsChange,
  handleSelectedListChange,
  handleSave,
  isWorkflowEditing,
}: any) => {
  // const workflowDataById = useAppSelector(
  //   (state: any) => state?.getWorkflow?.workflowData
  // );

  const handleRadioChange = (event: any) => {
    // console.log("event", event.target, typeof event.target.value);

    setSelectedRadioValue(Number(event.target.value));
    handleStepDataChange(expandedStep, {
      stepType: Number(event.target.value),
    });
    // setStepData({ ...stepData, responseType: event.target.value });
  };
  // console.log(selectedRadioValue);
  const handleAccordionChange =
    (panel: any) => (event: any, isExpanded: any) => {
      setExpandedStep(isExpanded ? panel : false);
    };

  useEffect(() => {
    // console.log("edit", expandedStep, workflowData);
    const radioValue = workflowData?.find(
      (item: any) => item?.step === expandedStep
    )?.stepType;
    // console.log(radioValue,"radioValue")
    setSelectedRadioValue(radioValue);
  }, [expandedStep]);

  // console.log("workflowData", workflowData, expandedStep, selectedRadioValue);

  return (
    <Accordion
      key={step}
      expanded={expandedStep === step}
      onChange={handleAccordionChange(step)}
      sx={{ mb: 2 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
          {isWorkflowEditing ? "Edit step" : "Step"} {step}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: "55vh",
          overflowY: "scroll",
          px: 2,
        }}
      >
        <RadioGroup
          value={selectedRadioValue}
          onChange={handleRadioChange}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mb: 2,
          }}
        >
          {RadioOptions?.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": { color: "green" },
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={option.label}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
            />
          ))}
        </RadioGroup>

        {selectedRadioValue == 1 && (
          <CustomResponseComponent
            expandedStep={expandedStep}
            selectedRadioValue={selectedRadioValue}
            handleStepDataChange={handleStepDataChange}
            workflowData={workflowData}
            handleEditorStateChange={handleEditorStateChange}
            handleVariablesChange={handleVariablesChange}
            handleButtonsChange={handleButtonsChange}
            handleSelectedListChange={handleSelectedListChange}
            handleSave={handleSave}
            isWorkflowEditing={isWorkflowEditing}
          />
        )}
        {selectedRadioValue == 2 && (
          <CustomResponseComponent
            expandedStep={expandedStep}
            selectedRadioValue={selectedRadioValue}
            handleStepDataChange={handleStepDataChange}
            workflowData={workflowData}
            handleEditorStateChange={handleEditorStateChange}
            handleVariablesChange={handleVariablesChange}
            handleButtonsChange={handleButtonsChange}
            handleSelectedListChange={handleSelectedListChange}
            handleSave={handleSave}
            isWorkflowEditing={isWorkflowEditing}
          />
        )}
        {selectedRadioValue == 3 && (
          // <Box>
          //   <Typography>Webhook Component (To be implemented)</Typography>
          //   {/* Implement your WebhookComponent here */}
          // </Box>
          <TriggerWebhookPage
            key={step}
            expandedStep={expandedStep}
            // stepType={selectedRadioValue}
            handleButtonsChange={handleButtonsChange}
            handleStepDataChange={handleStepDataChange}
            workflowData={workflowData}
            handleSelectedListChange={handleSelectedListChange}
            handleVariablesChange={handleVariablesChange}
            handleEditorStateChange={handleEditorStateChange}
            handleSave={handleSave}
            isWorkflowEditing={isWorkflowEditing}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default StepsComponent;
