import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import ExportWhiteIcon from "../../assets/svgs/ExportWhiteIcon";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import ContactsFilterPopover from "../../components/ContactsComponents/ContactsFilterPopOver";
import { BiSolidCategory } from "react-icons/bi";
import { MdChecklist, MdDelete } from "react-icons/md";
import { VscIssueDraft } from "react-icons/vsc";
import { IoIosCloseCircle } from "react-icons/io";
import { SiMarketo, SiTicktick } from "react-icons/si";
import { GiSandsOfTime } from "react-icons/gi";
import TemplatesFilterPopover from "../../components/TemplateComponents/TemplatesFilterPopover";
import ReactECharts from "echarts-for-react";
import { GrDocumentPerformance } from "react-icons/gr";
import { fetchOverviewAnalysisByCompanyId } from "../../redux/slices/Analytics/OverviewAnalyticsSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import { ANALYTICS_API } from "../../Apis/Analytics/Analytics";
import DateRangePicker from "../../components/AnalyticsComponent/DatePickerComponent";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  grayColor1: {
    color: "#303030",
    opacity: "90%",
    fontSize: "13px",
    // padding:"5px"
  },
  rotatedIcon: {
    cursor: "pointer",
    paddingRight: "5px",
    transform: "rotate(180deg)",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  messageCountContainer: {
    height: "31px",
    border: "1px solid #F2F2F2",
    cursor: "pointer",
    borderRadius: "5px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

const subOptions = {
  Category: [{ id: 1, option: "Marketing" }],
  // SubCategory: subCategoriesList,
  Status: [
    { id: 1, option: "Pending" },
    { id: 2, option: "Approved" },
    { id: 3, option: "Rejected" },
    { id: 4, option: "Draft" },
    { id: 5, option: "Deleted" },
  ],
};

const options = [
  {
    id: 1,
    option: "View All",
  },
  {
    id: 2,
    option: "Category",
  },
  // {
  //   id: 3,
  //   option: "SubCategory",
  // },
  {
    id: 3,
    option: "Status",
  },
];

const AnalyticsOverview = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const overViewData = useAppSelector((state: any) => state?.overViewAnalysis);
  // console.log('overViewData', overViewData?.data?.data);
  // const [pageData, setPageData] = useState(overViewData.data || []);
  const [agentResponseTimes, setAgentResponseTimes] = useState(
    overViewData?.data?.data?.agentResponseTimes || [
      { range: "<10m", count: 0 },
      { range: "10-50", count: 0 },
      { range: "50-75", count: 0 },
      { range: "75-100", count: 0 },
      { range: ">100m", count: 0 },
    ]
  );
  const [firstAgentResTimes, setFirstAgentResTimes] = useState(
    overViewData?.data?.data?.firstAgentResponseTimes || [
      { range: "<10m", count: 0 },
      { range: "10-50", count: 0 },
      { range: "50-75", count: 0 },
      { range: "75-100", count: 0 },
      { range: ">100m", count: 0 },
    ]
  );
  const [resolTimes, setResolTimes] = useState(
    overViewData?.data?.data?.resolutionTimes || [
      { range: "<10m", count: 0 },
      { range: "10-50", count: 0 },
      { range: "50-75", count: 0 },
      { range: "75-100", count: 0 },
      { range: ">100m", count: 0 },
    ]
  );
  const [averageResponseTime, setAverageResponseTime] = useState(
    overViewData?.data?.data?.averageResponseTime || 0
  );
  const [conversationDuration, setConversationDuration] = useState(
    overViewData?.data?.data?.conversationDuration || 0
  );
  const [totalConversation, setTotalConversation] = useState(
    overViewData?.data?.data?.totalConversation || 0
  );
  const [isExportAllByIdLoading, setIsExportAllByIdLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0]; // Formats the date to 'YYYY-MM-DD'
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    setIsLoadingMore(true);
    if (overViewData.status === "succeeded") {
      setAgentResponseTimes(overViewData?.data?.data?.agentResponseTimes);
      setFirstAgentResTimes(overViewData?.data?.data?.firstAgentResponseTimes);
      setResolTimes(overViewData?.data?.data?.resolutionTimes);
      setAverageResponseTime(
        overViewData?.data?.data?.averageResponseTime || 0
      );
      setConversationDuration(
        overViewData?.data?.data?.conversationDuration || 0
      );
      setTotalConversation(overViewData?.data?.data?.totalConversation || 0);
      setIsLoadingMore(false);
    }
  }, [overViewData]);

  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(
    null
  );
  const [anchorElTemplate, setAnchorElTemplate] = useState<null | HTMLElement>(
    null
  );
  const [selectedFilter, setSelectedFilter] = useState("Tags");
  const [selectedFilter2, setSelectedFilter2] = React.useState({
    column: "",
    value: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [teamData, setTeamData] = useState<any>([]);
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const today = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0]; // Formats the date to 'YYYY-MM-DD'
  };
  const [endCalenderDate, setEndCalenderDate] = useState(today());
  const [startCalenderDate, setStartCalenderDate] = useState(today());

  useEffect(() => {
    const postData = {
      userId: userData?.userId,
      companyId: userData?.companyId,
      fromDate: startCalenderDate,
      toDate: endCalenderDate,
      tags: teamData,
      isExportData: false,
      filters: {
        sorting: {
          column: "",
          order: "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: selectedFilter2?.column,
              operator: "equal",
              value: selectedFilter2?.value,
            },
          ],
        },
      },
    };

    dispatch(fetchOverviewAnalysisByCompanyId(postData));
    // console.log("fetchOverviewAnalysisByCompanyId", agentData);
  }, [dispatch, selectedFilter2, endCalenderDate, startCalenderDate, teamData]);

  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  const activeTagsArray: any = getContactTag.filter((tag: any) => tag.isActive);
  const filteredTags = activeTagsArray?.filter((tag: any) =>
    tag?.tag?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorElFilter(null);
  };

  const handleTemplateFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTemplate(event.currentTarget);
  };

  const handleCloseTemplatePopover = () => {
    setAnchorElTemplate(null);
  };

  const handleOptionClick = (option: string) => {
    setSelectedFilter(option);
    handleCloseFilterPopover();
  };

  const handleDateRangeChange = ({ startDate, endDate }: any) => {
    setStartCalenderDate(startDate);
    setEndCalenderDate(endDate);
    setIsDateRangeApplied(false);
    // console.log("StartCalenderDate", startCalenderDate, "EndCalenderDate", endCalenderDate)
  };

  const handleOptionClick2 = (option: string, subOption: string) => {
    setSelectedFilter2({
      column: option,
      value: subOption,
    });
    handleCloseTemplatePopover();
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const getIconComponent = (option: any) => {
    // console.log('Option.....',option);
    switch (option) {
      case "Marketing":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiMarketo />
            </span>
            &nbsp; Marketing
          </span>
        );
      // Add more cases as needed
      case "1":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <GiSandsOfTime />
            </span>
            &nbsp; Pending
          </span>
        );
      case "2":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiTicktick />
            </span>
            &nbsp; Approved
          </span>
        );
      case "3":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <IoIosCloseCircle />
            </span>
            &nbsp; Rejected
          </span>
        );
      case "4":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <VscIssueDraft />
            </span>
            &nbsp; Draft
          </span>
        );
      case "5":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdDelete />
            </span>
            &nbsp; Deleted
          </span>
        );
      case "":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdChecklist />
            </span>
            &nbsp; View All
          </span>
        );
      case "Category":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <BiSolidCategory />
            </span>
            &nbsp; Category
          </span>
        );
      case "Status":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}></span>&nbsp; Status
          </span>
        );
      default:
        return ""; // Default icon or content
    }
  };

  const StatsCard = ({ title, value, change, bgColor, textColor }: any) => {
    return (
      <Paper
        style={{
          backgroundColor: bgColor,
          padding: "16px",
          borderRadius: "12px",
          color: textColor,
          width: "32%",
          height: "120px",
          boxShadow: "none",
        }}
      >
        <Typography sx={{ color: "#666", fontWeight: "500" }}>
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
          mt={3}
        >
          <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>
            {value}
          </Typography>
          {/* <Typography
            variant="body2"
            style={{ color: change >= 0 ? "green" : "red" }}
            mt={2}
          >
            {change >= 0 ? `+${change}` : change}
          </Typography> */}
        </Box>
      </Paper>
    );
  };

  // const data = [
  //   { name: "<10m", value: 200 },
  //   { name: "10-50", value: 150 },
  //   { name: "50-75", value: 100 },
  //   { name: "75-100", value: 300 },
  //   { name: ">100m", value: 220 },
  // ];

  function BarChartCard2({ title, data }: any) {
    const options = {
      xAxis: {
        type: "category",
        data: data.map((item: any) => item.range),
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
        axisLabel: {
          color: "#999",
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: false,
        },
        axisLabel: {
          color: "#999",
          formatter: (value: number) => Math.floor(value), // Display y-axis values as whole numbers me
        },
        splitLine: {
          lineStyle: {
            color: "#f0f0f0",
          },
        },
        minInterval: 1, // Ensure y-axis difference between values is at least 1
      },
      series: [
        {
          data: data.map((item: any) => item.count),
          type: "bar",
          barWidth: "50%",
          itemStyle: {
            color: "#d3d3d3", // Light grey color for the bars
            borderRadius: [5, 5, 0, 0], // Rounded corners at the top
          },
        },
      ],
      grid: {
        top: "5%",
        left: "3%",
        right: "3%",
        bottom: "5%",
        containLabel: true,
      },
    };

    return (
      <Paper
        sx={{
          padding: 2,
          borderRadius: 2,
          width: "32%",
          boxShadow: "0 0 10px #eee",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#666", fontWeight: "500" }}
          gutterBottom
        >
          {title}
        </Typography>
        <ReactECharts option={options} style={{ height: 300 }} />
      </Paper>
    );
  }

  const handleExportById = async () => {
    // const hasPermission = hasExportByIdPermission(manageContactsObject);
    try {
      let data = {
        userId: userData?.userId,
        companyId: userData?.companyId,
        tags: teamData,
        // pageNumber: pageNumber,
        fromDate: startCalenderDate,
        toDate: endCalenderDate,
        isExportData: true,
        per_page: 40,
      };
      setIsExportAllByIdLoading(true);
      const getResponseById = await ANALYTICS_API.getGetOverviewAnalytics(data);
      let rdata: string = getResponseById.data.data;
      // Decode base64 string to binary data
      const byteCharacters = atob(rdata);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a blob from the byte array
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = "exported_AnalyticsOverView.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsExportAllByIdLoading(false);
    } catch (error) {
      console.error("Error exporting Analytics OverView:", error);
      setIsExportAllByIdLoading(false);
    }
  };
  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
    // console.log("selectedDate", selectedDate);
    // console.log("event.target.value", event.target.value);
  };

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        <Box
          sx={{ display: "flex", flexDirection: "column", height: "80px" }}
          mt={1}
        >
          <Box className={classes.manageContainer}>
            <Box my={2} mx={3}>
              <Typography
                variant="h6"
                className={classes.blackColor}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <GrDocumentPerformance />
                &nbsp;Overview
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            ml={2}
          >
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box className={classes.messageCountContainer}>
                <Typography
                  className={classes.grayColor1}
                  sx={{ fontSize: "13px" }}
                  onClick={handleFilterClick}
                >
                  {selectedFilter}
                </Typography>
                <Box className={classes.iconStyles} onClick={handleFilterClick}>
                  <ArrowDownSvg />
                </Box>
                <ContactsFilterPopover
                  anchorEl={anchorElFilter}
                  handleClose={handleCloseFilterPopover}
                  options={filteredTags}
                  handleOptionClick={handleOptionClick}
                  handleSearchChange={handleSearchChange}
                  setSearchQuery={setSearchQuery}
                  setPage={setPage}
                  setTeamData={setTeamData}
                />
              </Box>
              {/* <Box className={classes.messageCountContainer} mx={2}>
                <Box className={classes.messageInnerContainer}>
                  <Typography variant="body2" className={classes.grayColor}>
                    Events
                  </Typography>
                  <Box className={classes.iconStyles}>
                    <ArrowDownSvg />
                  </Box>
                </Box>
              </Box> */}
              {/* <Box className={classes.messageCountContainer}  mx={2}>
                <Box className={classes.messageInnerContainer}>
                  <Typography
                    className={classes.grayColor}
                    style={{
                      padding: "3px",
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {getIconComponent(selectedFilter2?.value)}
                    &nbsp; &nbsp;|
                  </Typography>
                  <Box
                    className={
                      anchorElTemplate
                        ? classes.rotatedIcon
                        : classes.iconStyles
                    }
                    onClick={handleTemplateFilterClick}
                  >
                    <ArrowDownSvg />
                  </Box>
                  <TemplatesFilterPopover
                    anchorEl={anchorElTemplate}
                    handleClose={handleCloseTemplatePopover}
                    options={options}
                    subOptions={subOptions}
                    handleOptionClick={handleOptionClick2}
                  />
                </Box>
              </Box> */}
              {/* <Box className={classes.messageCountContainer} mx={2}>
                <TextField
                  // label="Select Date"
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true, // Ensures the label is displayed above the input field
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-root": {
                      height: "31px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px",
                    },
                  }}
                />
              </Box> */}
              <Box>
                <Box
                  ml={2}
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between"
                    border: "1px solid #eee",
                    borderRadius: "5px",
                    height: "31px",
                  }}
                >
                  <DateRangePicker
                    onDateRangeChange={handleDateRangeChange}
                    // disabled={transactionData && transactionData?.length < 1}
                    startCalenderDate={startCalenderDate}
                    endCalenderDate={endCalenderDate}
                  />
                  {/* <Button
                    // disabled={!startCalenderDate || !endCalenderDate}
                    onClick={handleApplyDateRange}
                    sx={{
                      color: "green",
                      fontSize: "12px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Tooltip title={tooltipText}>{icon}</Tooltip>
                  </Button> */}
                </Box>
              </Box>
            </Box>
            <Box mr={3}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // backgroundColor: bgColors.blue,
                  borderRadius: "8px",
                  padding: "10px",
                  cursor: "pointer",
                  width: "130px",
                  height: "32px",
                  color: bgColors.green,
                  border: `1px solid ${bgColors.green}`,
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "rgba(68, 71, 70, 0.08)",
                  },
                }}
              >
                {isExportAllByIdLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={handleExportById}
                  >
                    <Typography variant="body2" style={{ padding: "5px" }}>
                      Export
                    </Typography>
                    <Box className={classes.iconStyles}>
                      <ExportWhiteIcon
                        style={{
                          fill: bgColors.green,
                          paddingTop: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <div>
          {overViewData?.status === "succeeded" && !isLoadingMore ? (
            <Box sx={{ flex: 1, overflow: "auto", px: 2 }}>
              <Box
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
                mt={5}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                  sx={{ width: "100%", padding: "2px" }}
                >
                  <StatsCard
                    title="Total Conversations"
                    value={totalConversation}
                    change={-1}
                    bgColor="#f0f4ff"
                    textColor="#000"
                  />
                  <StatsCard
                    title="Conversation Duration"
                    value={`${conversationDuration} Mins`}
                    change={-5}
                    bgColor="#f8f4ff"
                    textColor="#000"
                  />
                  <StatsCard
                    title="Average Response Time"
                    value={`${averageResponseTime} Mins`}
                    change={5}
                    bgColor="#f4fff4"
                    textColor="#000"
                  />
                </Box>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{ width: "100%" }}
                  mt={3}
                  pb={1}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={"auto"}
                    sx={{ width: "100%", padding: "2px" }}
                  >
                    <BarChartCard2 title="Resolution Time" data={resolTimes} />
                    <BarChartCard2
                      title="Average Wait Time for Agent Responses"
                      data={agentResponseTimes}
                    />
                    <BarChartCard2
                      title="Wait Time for 1st Agent Response"
                      data={firstAgentResTimes}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <LoadingComponent height="67vh" color={bgColors.blue} />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default AnalyticsOverview;
