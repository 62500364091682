import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { getVariableNames } from "../../../redux/slices/Workflows/getVariableNamesSlice";
import WebhookAccordions from "./triggerWebhookComponent";
import {
  extractVeriablesFromString,
  getNextVariableCount,
  getNextVariableCountFromAllHeaders,
} from "./functions";
import { validateUrl } from "../../../utils/functions";
import { validateJson } from "../../../utils/functions";

interface Header {
  key: string;
  value: string;
}

const TriggerWebhookPage = ({
  expandedStep,
  handleStepDataChange,
  workflowData,
  handleSelectedListChange,
  handleButtonsChange,
  handleVariablesChange,
  handleEditorStateChange,
  handleSave,
  isWorkflowEditing,
}: any) => {
  const dispatch = useAppDispatch();
  const requestUrlInputRef = useRef<HTMLInputElement>(null);
  const headerValueInputRef = useRef<HTMLInputElement>(null);
  const bodyDescriptionInputRef = useRef<HTMLInputElement>(null);

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const variableNames = useAppSelector((state: any) => state.getVariableNames);

  const [isAddNewPopupOpen, setIsAddNewPopupOpen] = useState(false);
  const [newVariableName, setNewVariableName] = useState("");
  const [requestType, setRequestType] = useState(
    workflowData[expandedStep - 1]?.webhookTriggerHttpMethod || "GET"
  );
  const [requestUrl, setRequestUrl] = useState(
    workflowData[expandedStep - 1]?.webhookTriggerUrl || ""
  );
  const [headers, setHeaders] = useState<Header[]>(
    workflowData[expandedStep - 1]?.webhookTriggerHeader || [
      { key: "Content-Type", value: "application/json" },
    ]
  );
  const [responseOption, setResponseOption] = useState("trait");
  const [errorDescription, setErrorDescription] = useState(
    workflowData[expandedStep - 1]?.defaultErrorResponse ||
      "We are sorry. Unable to process your request at this time. Please try again later."
  );
  const [bodyDescription, setBodyDescription] = useState(
    workflowData[expandedStep - 1]?.webhookTriggerBody || "{}"
  );
  const [showSaveUserResponse, setShowSaveUserResponse] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [jsonError, setJsonError] = useState(false);

  const handleAddNewClick = () => {
    setIsAddNewPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsAddNewPopupOpen(false);
    setNewVariableName("");
  };
  const handlePopupSuccess = () => {
    // Refresh the variable list
    // fetchWorkflowVariableNames();
  };
  // Handle request URL changes
  const handleRequestTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequestType(event.target.value);

    // Call handleStepDataChange to update the webhookTriggerHttpMethod field
    handleStepDataChange(expandedStep, {
      webhookTriggerHttpMethod: event.target.value,
    });
  };

  const handleRequestUrlChange = (value: string) => {
    // Extract current variables from requestUrl
    const currentVariables = extractVeriablesFromString(requestUrl);

    // Extract new variables from the updated value
    const newVariables = extractVeriablesFromString(value);
    if (validateUrl(value) || value === "") {
      setUrlError(false); // No error if the URL is valid or empty
    } else {
      setUrlError(true); // Set error if URL is invalid
    }

    // Find removed variables
    const removedVariables = currentVariables?.filter(
      (variable) => !newVariables.some((v) => v.veriable === variable.veriable)
    );

    // Update requestUrl
    setRequestUrl(value);

    // Update the workflow data by removing the veriables that no longer exist
    const updatedWorkflowData = workflowData?.map(
      (step: any, index: number) => {
        if (index === expandedStep - 1) {
          return {
            ...step,
            veriables: step?.veriables?.filter(
              (variable: any) =>
                !removedVariables.some(
                  (v) =>
                    v.veriable === variable.veriable && variable?.type === 2
                )
            ),
          };
        }
        return step;
      }
    );

    // Update the state with the modified workflowData
    handleVariablesChange(
      expandedStep,
      updatedWorkflowData[expandedStep - 1]?.veriables || []
    );

    // Call handleStepDataChange to update the webhookTriggerHttpMethod field
    handleStepDataChange(expandedStep, {
      webhookTriggerUrl: value,
    });
  };

  // Handle header changes
  const handleHeaderChange = (index: number, field: string, value: string) => {
    // If the field is 'value', handle variable extraction
    if (field === "value") {
      // Extract current variables from the header value
      const currentVariables = extractVeriablesFromString(
        headers[index]?.value
      );

      // Extract new variables from the updated value
      const newVariables = extractVeriablesFromString(value);

      // Find removed variables
      const removedVariables = currentVariables?.filter(
        (variable) =>
          !newVariables.some((v) => v.veriable === variable.veriable)
      );

      // Update workflow data by removing variables that no longer exist
      const updatedWorkflowData = workflowData?.map(
        (step: any, stepIndex: number) => {
          if (stepIndex === expandedStep - 1) {
            return {
              ...step,
              veriables: step?.veriables?.filter(
                (variable: any) =>
                  !removedVariables.some(
                    (v) =>
                      v.veriable === variable.veriable &&
                      variable?.type === 4 &&
                      variable?.index === index
                  )
              ),
            };
          }
          return step;
        }
      );
      // console.log("updated workflow data", updatedWorkflowData);

      // Update the variables in the state
      handleVariablesChange(
        expandedStep,
        updatedWorkflowData[expandedStep - 1]?.veriables || []
      );
    }

    // Update the headers array
    const updatedHeaders = headers.map((header, i) =>
      i === index ? { ...header, [field]: value } : header
    );

    setHeaders(updatedHeaders);

    // Update headers in stepData state
    handleStepDataChange(expandedStep, {
      webhookTriggerHeader: updatedHeaders,
    });
  };

  // Add a new header field
  const handleAddHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  // Remove a header
  const handleRemoveHeader = (index: number) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const toggleSaveUserResponse = () => {
    setShowSaveUserResponse(!showSaveUserResponse);
  };

  const handleErrorDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorDescription(event.target.value);

    // Update headers in stepData state
    handleStepDataChange(expandedStep, {
      defaultErrorResponse: event.target.value,
    });
  };

  const handleBodyDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      validateJson(event.target.value);
      setJsonError(false); // No error if JSON is valid
    } catch (error) {
      setJsonError(true); // Set error if JSON is invalid
    }
    setBodyDescription(event.target.value);

    // Update body in stepData state
    handleStepDataChange(expandedStep, {
      webhookTriggerBody: event.target.value,
    });
  };

  const handleSaveUserVariableChange = (event: any) => {
    const selectedVariableId = event.target.value;
    setSelectedVariable(selectedVariableId);

    // Update the workflow data with the selected variable id
    const updatedWorkflowData = {
      ...workflowData[expandedStep - 1],
      responseCustomerMessage: {
        ...workflowData[expandedStep - 1].responseCustomerMessage,
        veriableNameEntityId: selectedVariableId,
      },
    };
    handleStepDataChange(expandedStep, updatedWorkflowData);
  };

  const handleAddVariable = (type: string, headerIndex?: number) => {
    // console.log("type", type, headerIndex);
    // Handle the "requestUrl" type
    if (type === "requestUrl") {
      // Get the next variable count
      const nextCount = getNextVariableCount(requestUrl, "text");

      // Construct the variable text
      const variableText = `{{${nextCount}}}`;

      const cursorPosition = requestUrlInputRef.current?.selectionStart || 0;

      // Insert the variableText at the cursor position in the requestUrl
      const updatedRequestUrl = [
        requestUrl.slice(0, cursorPosition),
        variableText,
        requestUrl.slice(cursorPosition),
      ].join("");

      // Update the request URL with the new variable
      setRequestUrl(updatedRequestUrl);
      handleEditorStateChange(expandedStep, updatedRequestUrl, "requestUrl");

      // Handle variables array update
      const currentVariables = workflowData[expandedStep - 1]?.veriables || [];
      const newVariable = {
        index: nextCount - 1,
        veriable: variableText,
        value: "",
        type: 2,
        fallbackValue: "",
        referenceTableType: 0,
      };
      const updatedVariables = [...currentVariables, newVariable];
      handleVariablesChange(expandedStep, updatedVariables);
    }
    // Handle the "headers" type
    else if (type === "headers" && typeof headerIndex === "number") {
      // console.log("headerIndex", headerIndex);

      // const currentVariablesArray =
      //   workflowData[expandedStep - 1]?.veriables || [];
      // const type4VariablesCount = currentVariablesArray?.filter(
      //   (v: any) => v.type === 4
      // ).length;
      // console.log("variablesCount", currentVariablesArray);

      // Get the next variable count for headers
      const nextCount = getNextVariableCountFromAllHeaders(headers);

      // Construct the variable text
      const variableText = `{{${nextCount}}}`;

      // Get the current cursor position
      const cursorPosition = headerValueInputRef?.current?.selectionStart || 0;

      // Get the current value of the header's value field
      const currentHeaderValue = headers[headerIndex]?.value || "";

      // Insert the variableText into the header's value field at the cursor position
      const updatedHeaderValue =
        currentHeaderValue?.slice(0, cursorPosition) +
        variableText +
        currentHeaderValue?.slice(cursorPosition);

      // Update the headers state with the new value
      const updatedHeaders = headers?.map((header, i) =>
        i === headerIndex
          ? {
              ...header,
              value: updatedHeaderValue, // Update value with the new string
            }
          : header
      );
      // console.log("updated headers", updatedHeaders);
      // Update the headers state
      setHeaders(updatedHeaders);
      handleEditorStateChange(expandedStep, updatedHeaders, "headers");
      // Set the cursor position after insertion (if needed)
      headerValueInputRef.current?.setSelectionRange(
        cursorPosition + variableText.length,
        cursorPosition + variableText.length
      );

      // Update the variables array for headers
      const currentVariables = workflowData[expandedStep - 1]?.veriables || [];
      const newVariable = {
        index: nextCount - 1,
        veriable: variableText,
        value: "",
        type: 4,
        fallbackValue: "",
        referenceTableType: 0,
      };
      const updatedVariables = [...currentVariables, newVariable];
      handleVariablesChange(expandedStep, updatedVariables);
    } else if (type === "body") {
      // Get the next variable count
      const nextCount = getNextVariableCount(bodyDescription, "text");

      // Construct the variable text
      const variableText = `{{${nextCount}}}`;

      const cursorPosition =
        bodyDescriptionInputRef?.current?.selectionStart || 0;

      // Insert the variableText at the cursor position in the requestUrl
      const updatedBodyDescription = [
        bodyDescription.slice(0, cursorPosition),
        variableText,
        bodyDescription.slice(cursorPosition),
      ].join("");

      // Update the request URL with the new variable
      setBodyDescription(updatedBodyDescription);
      handleEditorStateChange(
        expandedStep,
        updatedBodyDescription,
        "bodyDescription"
      );

      // Handle variables array update
      const currentVariables = workflowData[expandedStep - 1]?.veriables || [];
      const newVariable = {
        index: nextCount - 1,
        veriable: variableText,
        value: "",
        type: 3,
        fallbackValue: "",
        referenceTableType: 0,
      };
      const updatedVariables = [...currentVariables, newVariable];
      handleVariablesChange(expandedStep, updatedVariables);
    }
  };

  useEffect(() => {
    const payload = {
      companyId: userData?.companyId,
    };
    dispatch(getVariableNames(payload));
    // setRequestUrl(workflowData[expandedStep - 1]?.webhookTriggerUrl);
    // setRequestType(workflowData[expandedStep - 1]?.webhookTriggerHttpMethod);
    // setHeaders(workflowData[expandedStep - 1]?.webhookTriggerHeader);
    // setBodyDescription(workflowData[expandedStep - 1]?.webhookTriggerBody);
    // setErrorDescription(workflowData[expandedStep - 1]?.defaultErrorResponse);
  }, [dispatch]);

  // console.log("requestUrl", requestUrl);

  return (
    <WebhookAccordions
      requestType={requestType}
      requestUrl={requestUrl}
      setRequestUrl={setRequestUrl}
      handleRequestTypeChange={handleRequestTypeChange}
      handleRequestUrlChange={handleRequestUrlChange}
      handleAddVariable={handleAddVariable}
      headers={headers}
      setHeaders={setHeaders}
      handleHeaderChange={handleHeaderChange}
      handleRemoveHeader={handleRemoveHeader}
      handleAddHeader={handleAddHeader}
      handleAddNewClick={handleAddNewClick}
      handlePopupClose={handlePopupClose}
      handlePopupSuccess={handlePopupSuccess}
      handleStepDataChange={handleStepDataChange}
      expandedStep={expandedStep}
      workflowData={workflowData}
      handleVariablesChange={handleVariablesChange}
      handleEditorStateChange={handleEditorStateChange}
      headerValueInputRef={headerValueInputRef}
      requestUrlInputRef={requestUrlInputRef}
      bodyDescriptionInputRef={bodyDescriptionInputRef}
      handleBodyDescriptionChange={handleBodyDescriptionChange}
      bodyDescription={bodyDescription}
      setBodyDescription={setBodyDescription}
      handleSaveUserVariableChange={handleSaveUserVariableChange}
      selectedVariable={selectedVariable}
      responseOption={responseOption}
      setResponseOption={setResponseOption}
      variableNames={variableNames}
      isAddNewPopupOpen={isAddNewPopupOpen}
      setNewVariableName={setNewVariableName}
      newVariableName={newVariableName}
      errorDescription={errorDescription}
      handleErrorDescriptionChange={handleErrorDescriptionChange}
      handleSelectedListChange={handleSelectedListChange}
      handleButtonsChange={handleButtonsChange}
      showSaveUserResponse={showSaveUserResponse}
      toggleSaveUserResponse={toggleSaveUserResponse}
      handleSave={handleSave}
      isWorkflowEditing={isWorkflowEditing}
      jsonError={jsonError}
      urlError={urlError}
    />
  );
};

export default TriggerWebhookPage;
