import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import QRCode from "qrcode.react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
const WhatsAppLinkPopup = ({ open, onClose, whatsappUrl }: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(whatsappUrl);
    setIsCopied(true); // Change the button text to "Copied"
    setTimeout(() => {
      setIsCopied(false); // Reset the button text back to "Copy URL" after a delay
    }, 5000);
  };

  const handleDownloadQR = () => {
    const canvas = document.getElementById("qr-code");
    if (canvas instanceof HTMLCanvasElement) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "whatsapp-qr.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.error("Failed to find the QR code element.");
    }
  };

  const handleWhatsappUrl = () => {
    window.open(whatsappUrl, "_blank"); // Opens in a new tab
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent sx={{ textAlign: "center", pt: 3, pb: 4 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          Here is your WhatsApp short link
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Copy and share it on your social media, website, emails or anywhere
          you want to be contacted instantly by your customers.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
            p: 1,
            bgcolor: "#f0f0f0",
            borderRadius: 1,
          }}
        >
          <WhatsAppIcon sx={{ color: "#25D366", mr: 1 }} />
          <Typography
            variant="body1"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={handleWhatsappUrl}
          >
            {/* {whatsappUrl} */}
            https://www.app.engageto.com/
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <QRCode id="qr-code" value={whatsappUrl} size={200} />
        </Box>
        {!isCopied ? (
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            FREE !!- Track links & much more, <a href="/login">Sign Up now</a>
          </Typography>
        ) : (
          <Typography variant="body2" color="green" sx={{ mb: 2 }}>
            Link has been copied to clipboard !!
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={!isCopied ? <ContentCopyIcon /> : null}
            onClick={handleCopyUrl}
            sx={{ bgcolor: "#25D366", "&:hover": { bgcolor: "#128C7E" } }}
          >
            {isCopied ? "Copied" : "Copy URL"}
          </Button>
          <Button
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={handleDownloadQR}
          >
            Download QR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WhatsAppLinkPopup;
