import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../utils/redux-hooks";
import { getWorkflowNames } from "../../../redux/slices/Workflows/getWorkflowNamesSlice";
import { getWorkflow } from "../../../redux/slices/Workflows/getWorkflowSlice";
import { toastActions } from "../../../utils/toastSlice";
// import { getWorkflowList } from "../../../redux/slices/Workflows/getWorkflowListSlice";

const LeftSidebar = () => {
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [isOpenWorkflowDialog, setOpenWorkflowDialog] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const { workflowNamesData, workflowNamesStatus } = useAppSelector(
    (state: any) => state.getWorkflowNames
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const payload = {
      companyId: userData?.companyId,
    };
    dispatch(getWorkflowNames(payload));
  }, [userData]);

  const templates = [
    "Lead Gen - Agency",
    "Lead Gen - with pincode",
    "Vehicle Insurance renewal",
    "Vehicle service booking",
    "Capture a Testimonial",
    "Lead Gen - New Student Registration",
    "Product Feedback",
    "NPS",
    "Appointment booking - General",
    "Lead Gen - all industry",
    "Healthcare supplements - lead gen",
    "Healthcare - Appointment Booking",
    "Pathology center - booking",
  ];

  const handleWorkflow = (item: any) => {
    // console.log("item", item);

    setSelectedWorkflow(item);
    const fetchWorkflows = async () => {
      const payload = {
        companyId: userData?.companyId,
        workflowName: item?.workflowName,
      };

      try {
        const result = await dispatch(getWorkflow(payload)).unwrap();
        if (result.success) {
          // Assuming you handle the data in Redux itself or you use it directly
          // dispatch(
          //   toastActions.setToaster({
          //     message: result.message,
          //     type: "success",
          //   })
          // );
          setOpenWorkflowDialog(true);
        } else {
          dispatch(
            toastActions.setToaster({
              message: result.message || "Failed to fetch workflow list",
              type: "error",
            })
          );
        }
      } catch (error: any) {
        // dispatch(
        //   toastActions.setToaster({
        //     message:
        //       error.message ||
        //       "An error occurred while fetching the workflow list",
        //     type: "error",
        //   })
        // );
      }
    };
    if (userData?.companyId) {
      fetchWorkflows();
    }
  };
  // console.log("selectedWorkflows", selectedWorkflow);

  return (
    <Box
      sx={{
        height: "75vh",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        padding: 1,
        overflowY: "scroll",
      }}
    >
      {/* <Accordion sx={{ borderRadius: "0px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderRadius: "0px",
            bgcolor: "white",
          }}
        >
          <Typography sx={{ padding: "0px", fontSize: 14, fontWeight: 500 }}>
            All Templates
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            height: "50vh",
            overflowY: "scroll",
            p: 0,
          }}
        >
          <List sx={{ padding: 0 }}>
            {templates?.map((template, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  px: 2,
                  py: "5px",
                  // bgcolor:
                  //   selectedWorkflowTemplate === template?.id
                  //     ? "#f1f1f1"
                  //     : "inherit", // Apply hover color when selected
                  "&:hover": {
                    bgcolor: "#f1f1f1",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Tooltip title={template} placement="top">
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                        }}
                      >
                        {template}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider /> */}
      <Accordion sx={{ borderRadius: "0px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderRadius: "0px",
            bgcolor: "white",
          }}
        >
          <Typography sx={{ padding: "0px", fontSize: 14, fontWeight: 500 }}>
            All Workflows
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            height: "50vh",
            overflowY: "scroll",
            p: 0,
          }}
        >
          <List sx={{ padding: 0 }}>
            {workflowNamesData?.map((item: any, index: number) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  px: 2,
                  py: "5px",
                  bgcolor: selectedWorkflow === item ? "#f1f1f1" : "inherit", // Apply hover color when selected
                  "&:hover": {
                    bgcolor: "#f1f1f1",
                  },
                  cursor: "pointer",
                }}
                onClick={() => handleWorkflow(item)}
              >
                <ListItemText
                  primary={
                    <Tooltip title={item.workflowName} placement="top">
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                        }}
                      >
                        {item.workflowName}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LeftSidebar;
