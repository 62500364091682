import React, { useState } from "react";
import { TextField, Popover, Box } from "@mui/material";
import { HexColorPicker } from "react-colorful";

const ColorPickerField = ({ value, onChange }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor: any) => {
    onChange(newColor);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <TextField
        label="Color"
        value={value}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: value,
                marginLeft: 1,
                borderRadius: 1,
                border: "1px solid grey",
                cursor: "pointer",
              }}
            />
          ),
        }}
        sx={{
          "& .MuiInputBase-root": {
            height: 40,
            borderRadius: 3,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: 3,
            },
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, 8px) scale(1)",
            "&.Mui-focused, &.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.75)",
            },
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <HexColorPicker color={value} onChange={handleColorChange} />
      </Popover>
    </Box>
  );
};

export default ColorPickerField;
