import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const WidgetsData = (data: any) => {
  return axios({
    url: `${USER_API_URL}/Widgets/create`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

export const MANAGE_INTEGRATIONS_APIS = {
  WidgetsData,
};
